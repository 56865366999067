@import "../../variables.scss";
footer {
  clear: both;
  background-color: $color-black;
  width: 100%;
  z-index: 0;
  border-top: 1px solid $color-darkgrey;
  @include mobile {
    padding: 50px 5%;
    box-sizing: border-box;
  }
  .foot-wrap {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 150px 0px 120px;
    @include mobile {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
  .social {
    width: 35%;
    display: inline-block;
    float: none;
    @include mobile {
      margin-top: 50px;
      float: none;
      text-align: center;
      width: 100%;
    }
    span {
      color: $color-white;
      font-size: 14px;
      display: block;
      line-height: 35px;
      @include font-book;
      margin-bottom: 20px;
      letter-spacing: 0.5px;
      &:after {
        content: "";
        width: 4px;
        height: 4px;
        margin-left: 5px;
        background-color: $color-red;
        display: inline-block;
        position: relative;
      }
    }
    a {
      display: inline-block;
      margin-right: 7px;
      margin-left: 0;
      background-color: transparent;
      font-size: 14px;
      color: $color-green;
      @include font-medium;
      @include mobile {
        background-color: $color-white;
        text-align: center;
        display: inline-block;
        margin: 0px 20px 0px 0px;
      }
      &:before {
        content: "";
        opacity: 0;
        transition: all ease 0.25s;
        position: absolute;
        display: inline-block;
        width: 4px;
        height: 22px;
        background-image: url(../../../public/img/footer-accent.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: 0%;
        left: -35px;
      }
      &:hover {
        color: $color-white;
        transform: none;
        background-color: transparent;
        &:before {
          opacity: 1;
          display: none;
          transform: translateX(15px);
        }
        @include mobile {
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
    &.desktop {
      @include mobile {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @include mobile {
        display: block;
      }
    }
  }
  nav {
    width: 100%;
    height: 100%;
    color: $color-black;
    z-index: 0;
    ul,
    li {
      list-style: none;
      padding-left: 0px;
      text-indent: 0px;
      margin-top: 0px;
    }
    .nav-wrap {
      position: relative;
      transition: all ease 0.5s 0.5s;
      width: 75%;
      display: inline-block;
      @include small-desktop {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
      }
      .large-nav {
        display: inline-block;
        width: 35%;
        vertical-align: top;
        @include mobile {
          width: 50%;
        }
        li {
          opacity: 1;
          transition: all ease 0.25s;
          padding-bottom: 30px;
          &:nth-of-type(1) {
            transition-delay: 0.25s;
          }
          &:nth-of-type(2) {
            transition-delay: 0.3s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.35s;
          }
          &:nth-of-type(4) {
            transition-delay: 0.4s;
          }
          &:before {
            display: none;
          }
          @include mobile {
            text-align: center;
          }
        }
        a {
          color: $color-white;
          font-size: 20px;
          text-decoration: none;
          transition: all ease 0.25s;
          position: relative;
          opacity: 0.25;
          @include font-bold;
          @include mobile {
            font-size: 16px;
          }
          &:before {
            content: "";
            opacity: 0;
            transition: all ease 0.25s;
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 22px;
            background-image: url(../../../public/img/footer-accent.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 5%;
            left: -35px;
          }
          &:hover {
            opacity: 1;
            &:before {
              opacity: 1;
              transform: translateX(15px);
            }
          }
        }
      }
      .small-nav {
        display: inline-block;
        width: 25%;
        vertical-align: top;
        @include mobile {
          margin-bottom: 30px;
          width: 50%;
        }
        li {
          opacity: 1;
          transition: all ease 0.25s;
          padding-bottom: 30px;
          &:nth-of-type(1) {
            transition-delay: 0.4s;
          }
          &:nth-of-type(2) {
            transition-delay: 0.45s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.5s;
          }
          &:before {
            display: none;
          }
          @include mobile {
            text-align: center;
          }
        }
        a {
          color: $color-white;
          font-size: 16px;
          text-decoration: none;
          transition: all ease 0.25s;
          position: relative;
          opacity: 0.25;
          @include font-bold;
          &:before {
            content: "";
            opacity: 0;
            transition: all ease 0.25s;
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 22px;
            background-image: url(../../../public/img/footer-accent.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 0%;
            left: -35px;
          }
          &:hover {
            opacity: 1;
            &:before {
              opacity: 1;
              transform: translateX(15px);
            }
          }
        }
      }
    }
    .signup-wrap {
      display: inline-block;
      width: 25%;
      vertical-align: top;
      text-align: left;
      max-width: 400px;
      @include small-desktop {
        width: 100%;
        text-align: left;
        float: none;
        max-width: 100%;
      }
      @include tablet {
        width: 100%;
        text-align: left;
        float: none;
        max-width: 100%;
      }
      @include mobile {
        width: 100%;
        text-align: center;
        float: none;
        max-width: 100%;
      }

      .link-wrap {
        color: $color-white;
        width: 100%;
        font-size: 18px;
        display: block;
        line-height: 35px;
        float: left;
        letter-spacing: 2px;
        margin-bottom: 5px;
        font-weight: bold;

        @include mobile {
          text-align: center;
          margin-top: 30px;
        }

        .info {
          font-weight: normal;
          &:after {
            content: "";
            width: 4px;
            height: 4px;
            margin-left: 5px;
            background-color: #eb1946;
            display: inline-block;
            position: relative;
          }
        }

        .text-link {
          margin-bottom: 0;
          padding-left: 0;

          &:after {
            top: 12px;
          }

          @include mobile {
            text-align: center;
            margin-bottom: 0;
            padding-bottom: 12px;
            margin-right: 0;
            &:after {
              display: none;
            }
          }
        }
      }
      .title-wrap {
        position: relative;
        display: block;
        height: 35px;
        @include mobile {
          text-align: center;
          margin-top: 30px;
        }
      }
      label {
        color: $color-white;
        font-size: 18px;
        display: block;
        line-height: 35px;
        float: left;
        letter-spacing: 2px;
        margin-bottom: 5px;
        @include mobile {
          float: none;
          text-align: center;
          font-size: 14px;
        }
      }
      input.email {
        display: block;
        background-color: $color-darkgrey;
        padding: 15px 10px;
        display: inline-block;
        text-align: center;
        border: none;
        transition: all ease 0.25s;
        color: $color-white;
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
        box-sizing: border-box;
        font-size: 16px;
        border-radius: 5px;
        width: 100%;
        @include font-bold;

        @include mobile {
          text-align: center;
        }
        &:focus,
        &:active {
        }
      }
      .button {
        display: block;
        width: 100%;
        &.disabled {
          opacity: 0.45;
          pointer-events: none;
        }
      }
    }
  }
  .legal {
    //float: right;
    color: $color-white;
    font-size: 14px;
    @include font-book;
    @include mobile {
      float: none;
      text-align: center;
      margin-top: 40px;
    }

    a {
      color: $color-white;
      margin-left: 20px;
      text-decoration: underline;
      @include mobile {
        display: block;
        clear: both;
        margin-top: 5px;
      }
    }
  }
}
