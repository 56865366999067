@import '../../variables.scss';

#careers{
  padding-top: 150px;
  position: relative;
  overflow: hidden;
  @include mobile{
    padding-top: 100px;
  }
  .crumb{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  h1{
    @include font-bold;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  h3{
    font-size: 20px;
    line-height: 25px;
  }
  .body-wrap{
    background-color: $color-white;
    width:100%;
    padding-top: 10vh;
    @include mobile{
      padding-top: 5vh;
    }
  }
  .type-wrap{
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 200px;
    p{
      max-width: 600px;
      width: 90%;
    }
    @include mobile{
      width: 100%;
      padding-bottom: 20px;
    }
  }
  .description{
    padding: 2% 5%;
    background-color: $color-white;
    width: 40%;
    display: inline-block;
    box-shadow: -15px 15px 25px rgba(0,0,0,.06);
    max-width: 350px;
    float: right;
    @include mobile{
      padding: 0px 0px;
      float: none;
      width: 100%;
      margin: 20px 0px 40px;
    }
    .body{
      @include mobile{
        margin: 40px auto 20px;
      }
      .link-list{
        li{
          text-indent: 0px;
          padding: 0px;
          line-height: inherit;
          &:before{
            display: none;
          }
        }
        a{
          margin-bottom: 15px;
          z-index: 5;
          white-space: nowrap;
          text-align: left;
        }
      }
    }
    p{
      color:$color-white;
      font-size: 27px;
      @include tablet{
        font-size: 24px;
      }
      @include mobile{
        font-size: 20px;
      }
    }
    .button-copy{
      width:60%;
      vertical-align: top;
      display: inline-block;
      margin-top: 0px;
      @include tablet{
        width:100%;
      }
      @include mobile{
        width:100%;
      }
      &:after{
        content:"\00d7";

        font-size: 35px;
        display: block;
        margin: 40px 0px 0px;

      }
    }
  }
  .text-link{
    text-transform: none;
  }
  p + .button{
    margin-top: 30px;
    display: inline-block;
    position: relative;
  }
}
