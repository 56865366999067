@import '../../variables.scss';

#case-study{
  float: left;
  padding-top: 68px;
  .hero-wrap{
    background-image: url(../../../public/img/background-pattern.png);
  }
  .crumb-link{
    margin-top: 80px;
    @include gradient-color;
    @include font-bold;
    margin-bottom: 10px;
    span{
      font-size: 15px;
    }
  }
  h1{
    color: $color-white;
  }
  .styled{
    margin-bottom: 20px;
    margin-top: 0px;
    + h3{
      margin-top: 0px;
      padding-bottom: 80px;
      font-size: 18px;
      line-height: 24px;
      @include mobile{
        padding-bottom: 20px;
      }
    }
  }
  .hero{
    position: relative;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    z-index: 1;
    img{
      min-width: 100%;
      @include mobile{
        min-width: 150%;
        margin-left: -25%;
      }
    }
    .content-wrap{
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      h1{
        color: $color-white;
      }
      h2{
        color: $color-white;

        font-size: 96px;
        line-height: 93px;
        margin-top: 0px;
        max-width: 1000px;
        @include desktop{
          font-size: 76px;
          line-height: 72px;
        }
        @include tablet{
          font-size: 56px;
          line-height: 52px;
        }
        @include mobile{
          font-size: 30px;
          line-height: 26px;
        }
      }
    }
  }
  .work-example{
    .work-wrap{
      max-width: 1300px;
      margin: 0 auto;
      width:90%;
      padding-bottom: 7vh;
    }
  }
  .copy-block{
    background-color: $color-white;
    &.text-content{
      margin: 20vh 0px;
    }
    .copy-wrap.intro{
      padding: 160px 5%;
      margin: 0 auto;
      max-width: 1300px;
      width: 90%;
      text-align: left;
      position: relative;
      @include tablet{
        padding: 15% 5%;
      }
      @include mobile{
        padding: 15% 5%;
      }
      &:before{
        content:'';
        background-color: $color-white;
        height: 100%;
        width: 200vw;
        display: block;
        position: absolute;
        top:-50%;
        left: -50%;
        z-index: 0;
        @media all and (max-width: 950px){
          top:0px;
        }
      }
      .left{
        width:50%;
        display: inline-block;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
        }
        h3{
          @include font-book;
          width:70%;
          @include tablet{
            width:100%;
          }
          @include mobile{
            width:100%;
          }
          &:before{
            content:'';
            background: $background-gradient;
            height: 4px;
            width: 20px;
            display: block;
            margin-top:-40px;
            position: relative;
            margin-bottom: 20px;
          }
        }
        .text-link{
          display: block;
          background: none;
          color:$color-black;
          -webkit-text-fill-color: inherit;
          text-fill-color: inherit;
          margin-bottom: 10px;
          padding-left: 0px;
        }
      }
      .right{
        width: 50%;
        display: inline-block;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
        }
      }
    }
    &.bordered{
      border-top: solid 2px $color-grey;
      border-bottom: solid 2px $color-grey;
      @include tablet{
        border: none;
      }
      @include mobile{
        border: none;
      }
    }
    span{
      display: inline-block;
      vertical-align: top;
      font-size: 100px;
      line-height: 100px;
      margin-right: 20px;
      margin-top: -25px;
      position: absolute;
      left: -95px;
      color: #7F899F;

      @include tablet{
        left: -75px;
      }
      @include mobile{
        margin-right: 2%;
        font-size: 75px;
        line-height: 75px;
        margin-top: -20px;
        position: relative;
        left: 0px;
        top:0px;
        display: inline-block;
      }
    }
    .situation, .role, .results, .insight, .textBlock{
      display: inline-block;
      width: 80%;
      vertical-align: top;
      max-width: 650px;
      text-align: left;
      @include tablet{
        width:75%;
      }
      @include mobile{
        width:90%;
      }
      .title{
        color: $color-blue;
      }
      a{
        color: $color-black;
        padding: 0px 5px;
        border: none;
        background: transparent;
        display: inline-block;
        text-decoration: none;
        position: relative;
        cursor: pointer;
        margin-right: 0px;
        height: 28px;
        transition: all ease .25s .0s;
        margin-bottom: 0px;
        @include mobile{
            font-size: 15px;
            padding: 0 5px;
            height: 20px;
        }
        &:after{
          content:'';
          width:100%;
          height: 2px;

          position: absolute;
          bottom: -7px;
          left: 0;
          display: block;
          transition: height ease .25s;
          z-index: -1;
        }
        &:hover{
          &:after{
            height: 35px;
            @include mobile{
              height: 40px;
            }
          }
        }
      }
    }
    .situation, .insight, .results{
      position: relative;
      @include mobile{
        margin-right: 0px;
      }
      .title{
        text-transform: uppercase;
        @include mobile{
          display: inline-block;
        }
        h3{
          display: inline-block;
          line-height: 33px;
          font-size: 33px;
          margin-top: 0px;
          color: $color-black;
          @include mobile{
            font-size: 24px;
            line-height: 25px;
          }
        }
        span{

          font-size: 71px;
          display: inline-block;
          @include mobile{
            font-size: 57px;
          }
          &:after{
            content: "";
            width: 2px;
            height: 50px;
            background-color: $color-blue;
            display: inline-block;
            margin: 0 30px 0px 10px;
            @include mobile{
              margin: 0 15px 0px 5px;
              height: 40px;
            }
          }
        }
      }
    }
  }
  .video-wrap{
    position: relative;
  	padding-bottom: 56.25%;
  	height: 0;
  	overflow: hidden;
    margin: 7vh auto;
    iframe{
      position: absolute;
    	top: 0;
    	left: 0;
    	border: 0;
    	width: 100%;
    	height: 100%;
      z-index: 1;
    }
    .loading{
      z-index: 0;
      height: auto;
      span{
        display: block;
      }
    }
    &.loop{
      padding-bottom: 63%;
      .thumbnail{
        display: none;
      }
    }
    @include tablet{
      margin: 2vh 0px;
    }
    @include mobile{
      margin: 2vh 0px;
    }
  }
  .image-wrap{
    width: 100%;
    display: block;
    padding: 7vh 0px;
    img{
      width: 100%;
    }
    @include tablet{
      padding: 2vh 0px;
    }
    @include mobile{
      padding: 2vh 0px;
    }
  }
  .video-boxed{
    position: relative;
    padding-bottom: 57.25%;
    height: 0;
    overflow: hidden;
    margin: 7vh auto;
    iframe, .thumbnail{
      position: absolute;
      top: 0%;
      left: 0%;
      border: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .loading{
      z-index: 0;
      height: auto;
      span{
        display: block;
      }
    }
    @include tablet{
      margin: 2vh 0px;
    }
    @include mobile{
      margin: 2vh 0px;
    }
  }
  .related-posts{
    .related-wrap{
      background-color: $color-grey;
      padding:50px 0px;
    }
    h3{

      font-size: 46px;
      color:$color-black;
      margin:0px auto 40px;
      text-align: center;
      width: 90%;
      @include mobile{
        font-size: 25px;
      }
    }
    h4{
      margin-top: 10px;

      font-size: 31px;
      text-transform: none;
    }
    .news-card{
      width: 31.25%;
      margin: 0 1% 20px;
      display: inline-block;
      padding: 20px;
      box-sizing: border-box;
      background-color:$color-grey;
      transition: all ease .25s;
      vertical-align: top;
      @include tablet{
        width: 100%;
      }
      @include mobile{
        width: 100%;
      }
      &:hover{
        background-color:$color-white;
      }
      .case-study-link{
        margin-top: 30px;
        border-bottom: 2px solid #DBDBDB;
        padding-bottom: 30px;
        background-size: 19px;
        width: 100%;
      }
    }
  }
  .related-projects{
    background-color: $color-white;
    h2{
      text-align: center;
      @include font-bold;
      font-size: 15px;
    }
    .slick-slider{
      margin: 0 auto 100px;
      .slick-list{
        .slick-slide{
          opacity: 0;
          transition: all ease .25s;
          &.slick-active{
            opacity: 1;
          }
        }
      }
    }
  }
  .collaborate{
    text-align: center;
    padding: 10vh 0px;
    background-color: $color-white;
    @include mobile{
      padding: 70px 0px;
    }
    h4{
      color:$color-blue;

      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
      text-transform: uppercase;
      color:$color-black;
      margin-top: 0px;
      @include mobile{
        font-size: 20px;
      }
    }
    .collab-button{

      font-size: 22px;
      z-index: 1;
    }
    .button{
      border-color:$color-blue;
      width: 85%;
      &:hover{
        background:$color-blue;
      }
      &:after{
        background-image:url(../../../public/img/arrow-black.svg);
      }
    }
    &.grey{
      background-color: $color-grey;
    }
  }
  .thanks{
    background-color:$color-blue;
    background-image: url(../../../public/img/eyeball.svg);
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px 0px;
    p{

      font-size: 14px;
      color:$color-white;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .LazyLoad{
    transition: all ease .5s;
    opacity: 0;
    transform: translateY(15px);
    display: block;
    position: relative;
    z-index: 1;
    &.is-visible{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .role{

    padding: 5vh 0px 14vh;
    @include mobile{
      overflow: hidden;
      width: 100vw;
      padding: 0px 0px 40px;
    }
    .hide{
      display: none;
    }
    .content-wrap{
      text-align: center;
      .capabilities_home{
        text-align: left;
      }
    }
    .title{
      font-weight: 100;
      font-size: 32px;
      line-height: 36px;
      padding-top: 25px;
      display: block;
      margin-bottom: 60px;
      color: $color-black;
      @include mobile{
        margin-bottom: 20px;
      }
      h3{
        margin-bottom: 0px;
        padding-bottom: 0px;
        text-align: center;
        @include mobile{
          font-size: 25px;
        }
      }
    }
    h4{
      text-transform: none;
    }
    li{
      &:first-of-type{
        margin-top: 20px;
      }
      &:before{
        display: none;
      }
    }
  }
  .slider-wrap{
      margin: 7vh auto;
      @include tablet{
        margin: 2vh 0px 5vh;
      }
      @include mobile{
        margin: 2vh 0px 5vh;
      }
  }
  .partner{
    background-color: $color-white;
    .content-wrap{
      padding: 20vh 0px 5vh;
      max-width: 1000px;
      margin: 0 auto;
    }
    .left{
      width:60%;
      @include tablet{
        width:100%;
      }
      @include mobile{
        width:100%;
      }

    }
    .left,
    .right{
      display: inline-block;
      vertical-align: middle;
    }
    .right{
      width:40%;
      text-align: center;
      @include mobile{
        width:100%;
      }
    }
    h3{
      @include font-book;
      margin-top: 0px;
      &:before{
        content:'';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top:-40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
    .text-link{
      background: none;
      color: $color-black;
      -webkit-text-fill-color: inherit;
      text-fill-color:inherit;
    }
  }
  .contact-form{
    background-color: $color-white;
    padding-bottom: 20vh;
    .content-wrap{
      max-width: 1000px;
      margin: 0 auto;
      input, label, select, textarea{
        display: block;
        width:100%;
        padding: 0px;
        border-radius: 0px;
        margin-bottom: 5px;
        vertical-align: top;
        font-size: 13px;
        @include font-medium;
      }
      input, select, textarea{
        border: 2px solid #e1e1e1;
        padding: 10px;
        background-color: #f1f1f1;
        margin-bottom: 15px;
        max-width: 100%;
        display: block;
        position: relative;
        box-sizing: border-box;
        color: $color-grey;
        resize: none;
        font-size: 15px;
      }
      .sneak{ //honeypot for spammers
        opacity: 0;
        position: absolute;
        z-index: -5;
      }
      .inline{
        width:31.33%;
        display: inline-block;
        vertical-align: top;
        margin-right: 3%;
        margin-bottom: 0px;
        &:nth-of-type(3){
          margin-right: 0;
        }
        @include mobile{
          width:100%;
        }
      }
      select{
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='52' height='32' viewBox='0 0 52 32'%3e%3cdefs%3e%3cstyle%3e .cls-1 %7b fill: %23c7c7c7; fill-rule: evenodd;%7d %3c/style%3e%3c/defs%3e%3cpath class='cls-1' d='M0.3,5.747L5.247,0.8,30.7,26.253l-4.95,4.95Z'/%3e%3cpath id='Rectangle_3_copy' data-name='Rectangle 3 copy' class='cls-1' d='M46.753,0.8l4.95,4.95L26.247,31.2l-4.95-4.95Z'/%3e%3c/svg%3e ");
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: 1.25em auto, 100%;
        appearance: none;
      }
      textarea{
        min-height: 198px;
        &[name="street"]{
          min-height: 0px;
        }
      }

      .left{
        vertical-align: top;
        display: inline-block;
        width:30%;
        margin-right: 4%;
        @include mobile{
          width: 100%;
        }
      }
      .right{
        vertical-align: top;
        display: inline-block;
        width:66%;
        @include mobile{
          width: 100%;
        }
      }
      .button{
        border: none;
        max-width: 300px;
        margin-bottom: 40px;
        color: $color-white;
        margin-right: 0px;
        float: right;
        border-radius: 10px;
        @include mobile{
          max-width: 100%;
        }
      }
      br{
        clear: both;
      }
    }
  }
  #ideas{
    padding-bottom: 150px;
    .button{
      display: none;
    }
    @include mobile{
      padding-bottom: 60px;
    }
  }

  .extended-footer{
    background-color: $color-black;
    padding: 10vh 0px;
    .section-wrap{
      width:90%;
      max-width: 1300px;
      margin: 0 auto;
    }
    .partnership-wrap,
    .demo-wrap{
      width:50%;
      display: inline-block;
      @include mobile{
        width:100%;
        margin-bottom: 20px;
      }
      .content-wrap{
        border-radius: 5px;
        display: block;
        position: relative;
        padding: 5%;
        box-sizing: border-box;
        z-index: 5;
        text-align: center;
        &:after,
        &:before{
          content:'';
          width: 101.15%;
          height: 103%;
          display: block;
          left: -.5%;
          top: -1.25%;
          position: absolute;
          background: $background-gradient;
          z-index: -2;
        }
        &:before{
          width:100%;
          height: 100%;
          left: 0px;
          top: 0px;
          background: $color-black;
          z-index: -1;
        }
        h3{
          color: $color-white;
          @include font-book;
        }
      }
    }
    .demo-wrap{
      .content-wrap{
        &:after{
          background: none;
          background-color: $color-darkgrey;
        }
      }
    }
  }

  .hidden{
    &.copy-block, &.left, &.right, &.video-wrap, &.image-wrap, .slick-slider, &.role, &.collaborate, &.related_projects, .content-wrap, &.video-boxed{
        opacity: 0;
        transform: translateY(20px);
      }
  }
  .fade-in-element{
    &.copy-block, &.left, &.right, &.video-wrap, &.image-wrap, .slick-slider, &.role, &.collaborate, &.related_projects, .content-wrap, &.video-boxed{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s .15s;
    }
    &.right{
      transition: all ease .75s .35s;
    }
  }
}
