@import '../../variables.scss';
#SpeakerPage{
  .content-wrap{
    padding-top: 200px;
    .crumb{
      @include gradient-color;
      @include font-bold;
      margin-bottom: 0px;
    }
    h1{
      color: $color-white;
      margin-top: 20px;
    }
    h3{
      color: $color-white;
      @include font-book;
      margin: 100px 0px;
      &:before{
        content:'';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top:-40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
    .button{
      float: right;
      padding: 15px 30px;
      @include tablet{
        display: none;
      }
      @include mobile{
        display: none;
      }
    }
  }
  .speakers-wrap{
    background-color: $color-white;
    padding-bottom: 20vh;
    position: relative;
    .speaker-wrap{
      padding: 20vh 0px 20vh;
      position: relative;
      max-width: 2000px;
      margin: 0 auto;
      @include tablet{
        padding: 10vh 0px 10vh;
      }
      @include mobile{
        padding: 10vh 0px 10vh;
      }
      &:before{
        content:'';
        position: absolute;
        z-index: 0;
        background-color: #F7F7F7;
        top:0vh;
        right: 0px;
        width:80%;
        height: 100%;
      }
      .image,
      .content{
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        z-index: 5;
        position: relative;
      }
      .image{
        width:40%;
        display: inline-block;
        box-shadow: 0px 15px 25px rgba(0,0,0,.2);
        border-radius: 10px;
        overflow: hidden;
        @include tablet{
          width:90%;
          margin: 0 auto;
          display: block;
        }
        @include mobile{
          width:90%;
          margin: 0 auto;
          display: block;
        }
        img{
          display: block;
        }
      }
      .content{
        width:40%;
        margin-left: 10%;
        @include tablet{
          width:90%;
          margin-left: 5%;
        }
        @include mobile{
          width:90%;
          margin-left: 5%;
        }
        h2{
          font-size: 30px;
          margin-bottom: -10px;
          @include tablet{
            margin-bottom: 0px;
          }
          @include mobile{
            margin-bottom: 0px;
          }
        }
        .title{
          @include gradient-color;
          @include font-bold;
          margin-top: 0px;
          font-size: 18px;
          display: inline;
        }
        .body{
          margin-bottom: 40px;
          p{
            font-size: 15px;
          }
        }
      }
      &:first-of-type{
        top:10vh;
        @include tablet{
          top:0px;
        }
        @include mobile{
          top:0px;
        }
        &:before{
          top: 5vh;
        }
      }
      &:nth-of-type(2n){
        background-color: #F7F7F7;
        .image{
          float: right;
          @include tablet{
            float: none;
          }
          @include mobile{
            float: none;
          }
        }
        &:before{
          left: 0px;
          background-color: $color-white;
        }
        .topics,
        .Collapsible{
          background-color: #F7F7F7;
        }
      }
      .button{
        padding: 15px 30px;
      }
      .topics{
        background-color: $color-white;
        border-radius: 5px;
        padding: 5%;
        margin: 60px 0px;
        h3{
          margin-top: 0px;
        }
        ul{
          padding-left: 0px;
          li{
            &:before{
              display: none;
            }
          }
        }
      }
      .Collapsible{
        position: relative;
        background-color: $color-white;
        padding: 5%;
        &__trigger{
          @include font-bold;
          font-size: 18px;
          @include gradient-color;
          border-bottom: 2px solid $color-red;
          display: block;
          padding-bottom: 5px;
          position: relative;
          cursor: pointer;
          &:after{
            content:'';
            width:15px;
            height: 15px;
            display: block;
            position: absolute;
            right: 0px;
            top:0px;
            background-image: url(../../../public/img/gradient-arrow.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: all ease .25s;
            transform: rotate(180deg);

          }
          &.is-open{
            &:after{
              transform: rotate(0deg);
            }
          }
        }
        .presentationGroup{
          margin-bottom: 20px;
          h4{
            text-transform: none;
            @include font-light;
            margin: 10px 0px 5px;
          }
          strong{
            @include font-bold;
            color: $color-black;
            text-transform: uppercase;
            margin: 0px;
            font-size: 15px;
          }
          p{
            font-size: 14px;
            margin: 0px;
          }
          &:last-of-type{
            margin-bottom: 0px;
          }
        }
      }
      &.hidden{
        opacity: 0;
        transform: translateY(20px);
        transition: all ease .5s 0s;
      }
      &.fade-in-element{
        opacity: 1;
        transform: translateY(0px);
        transition: all ease .5s .25s;
      }
    }
  }
}
