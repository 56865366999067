@import '../../variables.scss';
#about{
  .copy-block{
    &.text-content{
      margin: 20vh 0px;
    }
    .copy-wrap.intro{
      padding: 160px 5%;
      margin: 0 auto;
      max-width: 1300px;
      width: 90%;
      text-align: left;
      position: relative;
      @include mobile{
        padding: 40px 5%;
      }
      .left{
        width:50%;
        display: inline-block;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
        }
        h1{
          @include font-bold;
          color: $color-white;
          margin-bottom: 80px;
          margin-top: 0px;
          &:after{
            content: '';
            margin-left: 10px;
            margin-top: 5px;
            width: 8px;
            height: 8px;
            background-color: #EB1946;
            display: inline-block;
            vertical-align: middle;
          }
        }
        h3{
          color: $color-white;
          width:70%;
          @include font-book;
          @include tablet{
            width:100%;
          }
          @include mobile{
            width:100%;
          }
          &:before{
            content:'';
            background: $background-gradient;
            height: 4px;
            width: 20px;
            display: block;
            margin-top:-40px;
            position: relative;
            margin-bottom: 20px;
          }
        }
      }
      .right{
        width: 50%;
        display: inline-block;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
        }
        p{
          color:$color-white;
          margin-top: 110px;
          @include tablet{
            margin-top:40px;
          }
          @include mobile{
            margin-top:40px;
          }
        }
      }
    }
    @include mobile{
      margin:80px auto;
    }
  }
  .capabilities{
    padding: 20vh;
    text-align: center;
    background-color: $color-white;
  }
  .team,
  .speakers{
    max-width: 2000px;
    margin: 0 auto;
    @include tablet{
      padding: 0vh 0px 10vh;
    }
    @include mobile{
      padding: 0vh 0px 10vh;
    }
    .image,
    .content{
      display: inline-block;
      width:50%;
      vertical-align: middle;
      margin: 0px;
      box-sizing: border-box;
      @include tablet{
        width:100%;
      }
      @include mobile{
        width:100%;
      }
      &.mobile{
        display: none;
        @include mobile{
          display: block;
        }
      }
    }
    .image{
      position: relative;
      img{
        width:100%;
      }
    }
    .content{
      padding: 8%;
      @include tablet{
        padding: 5%;
      }
      @include mobile{
        padding: 5%;
      }
      h3{
        color: $color-white;
      }
      p{
        color: $color-white;
        margin-bottom: 40px;
      }
      .button{
        padding: 15px 30px;
      }
    }
  }
  .speakers{
    position: relative;
    padding: 13vh 0px;
    background-color: $color-white;
    @include tablet{
      padding: 0vh 0px 10vh;
    }
    @include mobile{
      padding: 0vh 0px 10vh;
    }
    .content{
      p, h3{
        color: $color-black;
      }
    }
    &:after{
      position: absolute;
      content:'';
      top:0px;
      left: -25vw;
      width: 150vw;
      height: 100%;
      background-color: $color-white;
      z-index: -1
    }
  }
  .hidden{
    .image, .content{
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .fade-in-element{
    .image{
      opacity: 1;
      transform: translateY(0px);
      transition:  all ease .5s .25s;
    }
    .content{
      opacity: 1;
      transform: translateY(0px);
      transition:  all ease .5s .45s;
    }
  }
}
