@import './variables.scss';
#recipes,
#recipe {
    font-family: var(--base-font);
    font-style: normal;
    position: relative;
    overflow: hidden;
    .page-texture {
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url('./img/paper-texture.png');
        background-size: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 5;
        opacity: 0.18;
        mix-blend-mode: multiply;
    }
    .stars {
        position: absolute;
    }
    button,
    .button {
        padding: 12px 24px;
        //styleName: Button 01;
        // font-family: Mark Pro;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        font-style: normal;
        line-height: 32px;
        min-width: 200px;
        letter-spacing: 0.04em;
        text-align: center;
        text-transform: uppercase;
        color: $creme;
        max-width: 440px;
        background: var(--inactive-button);
        border: 1px solid transparent;
        transition: all 0.2s ease;
        box-shadow: 6px 6px 1px rgba(0, 0, 0, 0.23);
        border-radius: 2px;
        cursor: pointer;
        &:hover {
            background-color: var(--active-button);
            color: $creme;
        }
        &.active {
            background-color: var(--active-button);
        }
        @include mobile {
            // height: 50px;
            flex-basis: 100%;
        }
        &.view-more {
            border-radius: 4px;
        }
    }
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }
    @media (min-width: 1200px) {
        .container {
            width: 1170px;
        }
    }
    header {
        background-color: $creme;
        max-width: none;
        width: 100%;
        position: static;
        left: 0;
        top: 0;
        z-index: 1;
        transform: translate(0, 0);
        padding-bottom: 50px;
        @include mobile {
            padding-bottom: 0px;
        }
        .container {
            @include bpUp(1200px) {
                max-width: 100%;
                width: calc(100% - 40px);
                margin-left: auto;
                margin-right: auto;
            }
            // margin-left: ;
        }
        .navigation {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-top: 0px;
            transform: translateY(33px);
            @include mobile {
                padding-top: 20px;
                transform: translateY(0px);
            }
            .share-wrap {
                margin-right: 40px;
                @include mobile {
                    // margin-right: 50px;
                }
                button.share {
                    background-color: $creme;
                    padding: 0;
                    color: black;
                    min-width: 0px;
                    box-shadow: none;
                    @include mobile {
                        font-size: 15px;
                    }
                }
                .share-link-wrap {
                    bottom: -60px;
                    // right: 50%;
                    // transform: translatex(50%);
                    @include mobile {
                        right: 0;
                        transform: translateX(0);
                    }
                }
            }
        }
        .subhead {
            text-align: center;
            //styleName: H3;
            // font-family: Mark Pro;
            font-size: 18px;
            line-height: 26px;
            font-style: normal;
            font-weight: 400;
            // line-height: 40px;
            letter-spacing: 0em;
            text-align: center;
            max-width: 709px;
            margin-left: auto;
            margin-right: auto;
            @include mobile {
                font-size: 16px;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
                line-height: 22px;
            }
        }
        h1 {
            margin-bottom: 12px;
            font-weight: 700;
            text-align: center;
            margin-top: 0px;
            @include mobile {
                margin-top: 30px;
                margin-bottom: 10px;
            }
            img {
                @include mobile {
                    height: auto;
                }
            }
        }
    }
    footer {
        text-align: center;
        color: $creme;
        padding-top: 31px;
        padding-bottom: 31px;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            a {
                max-height: 40px;
                display: flex;
                align-items: center;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
                color: $creme;
            }
            .bold {
                text-transform: uppercase;
                padding-left: 15px;
                font-weight: 700;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: $creme;
            }
            img {
                padding-left: 15px;
                object-fit: contain;
            }
        }
    }
    .recipe-card {
        flex-basis: calc(33.33% - 20px);
        border: 1px solid transparent;
        min-height: 370px;
        max-height: 500px;
        position: relative;
        color: black;
        display: flex;
        justify-content: center;
        // align-items: center;
        @include mobile {
            flex-basis: 100%;
            min-height: 343px;
        }
        img {
            position: absolute;
            width: 100%;
            max-height: 100%;
            // height: 100%;
            z-index: 2;
            top: 0;
            left: 0;
            object-fit: contain;
            cursor: pointer;
            transition: all 0.3s ease;
            @include mobile {
                // margin-bottom: 20px;
            }
            &:hover {
                filter: brightness(1.25)
            }
        }
    }
    .share-wrap {
        position: relative;
        z-index: 8;
        button.share {
            background-color: var(--inactive-button);
            // padding: 0;
            color: $creme;
            justify-content: center;
            // min-width: 0px;
            display: flex;
            align-items: center;
            gap: 10px;
            letter-spacing: 0.00em;
        }
        .share-link-wrap {
            position: absolute;
            bottom: -60px;
            width: calc(100% - 23px);
            right: 0;
            background-color: var(--inactive-button);
            // border: 1px solid black;
            opacity: 0;
            visibility: hidden;
            flex-wrap: wrap;
            transition: opacity 0.2s ease;
            border-radius: 4px;
            padding: 12px;
            justify-content: center;
            &.active {
                display: flex;
                opacity: 1;
                visibility: visible;
                // transform: scale(1);
            }
            a {
                color: white;
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                // display: inline-block;
                // margin-right: 20px;
                // padding: 20px;
                // padding: 20px;
                &.facebook,
                &.mail {
                    background-color: var(--active-button);
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                &:hover {
                    opacity: 1;
                }
                // opacity: 0;
            }
        }
    }
    .flourish {
        position: absolute;
        height: 88px;
        width: 88px;
        background-image: url('./img/flourish.png');
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 2;
        pointer-events: none;
        &.top {
            top: 12px
        }
        &.bottom {
            bottom: 12px;
        }
        &.left {
            left: 12px
        }
        &.right {
            right: 12px;
        }
        &.top.left {
            transform: rotate(180deg);
        }
        &.top.right {
            transform: rotate(270deg);
        }
        &.bottom.left {
            transform: rotate(90deg);
        }
    }
    .rect {
        position: absolute;
        height: calc(100% - 15px);
        width: calc(100% - 15px);
        border: 2px solid $gold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        z-index: 3;
        pointer-events: none;
    }
    main {
        .container {
            position: relative;
        }
    }
}