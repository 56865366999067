@import '../RecipesIndex/variables.scss';
#recipe {
    background-color: $creme;
    .flourish-wrap {
        position: relative;
    }
    header {
        .navigation {
            justify-content: space-between;
            align-items: center;
            transform: translateY(20px);
            @include mobile {
                transform: translateY(0px);
            }
            .mobile-placeholder {
                display: none;
                @include mobile {
                    display: block;
                }
            }
            .back {
                background-color: var(--inactive-button);
                border-radius: 6px;
                height: 56px;
                width: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    main {
        section.recipe-breakdown {
            padding-bottom: 50px;
            .container {
                display: flex;
                flex-wrap: wrap;
                h2 {
                    margin-top: 0;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 7px;
                    color: #000;
                }
                button {
                    box-shadow: none;
                }
                .left {
                    flex-basis: 50%;
                    @include mobile {
                        flex-basis: 100%;
                        margin-bottom: 0px;
                    }
                    .img-wrap {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;
                        img {
                            max-width: 440px;
                            @include mobile {
                                max-width: 100%;
                            }
                        }
                    }
                    .ingredients {
                        text-align: center;
                        max-width: 400px;
                        margin-left: auto;
                        margin-right: auto;
                        background-color: var(--inactive-button);
                        color: $creme;
                        padding: 60px 20px 70px;
                        position: relative;
                        .rect {
                            height: calc(100% - 36px);
                            width: calc(100% - 36px);
                            border: 3px solid $gold;
                        }
                        .flourish {
                            &.top {
                                top: 24px
                            }
                            &.bottom {
                                bottom: 24px;
                            }
                            &.left {
                                left: 24px
                            }
                            &.right {
                                right: 24px;
                            }
                        }
                        @include mobile {
                            display: none;
                        }
                        .title {
                            font-size: 22px;
                            margin-bottom: 7px;
                            font-weight: bold;
                        }
                        .list {
                            font-size: 18px;
                            line-height: 28px;
                            letter-spacing: 0em;
                            padding: 0 14px;
                        }
                    }
                }
                .right {
                    flex-basis: 50%;
                    @include mobile {
                        flex-basis: 100%;
                    }
                    .share-wrap {
                        max-width: 200px;
                        padding-bottom: 10px;
                        @include mobile {
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .share-link-wrap {
                            bottom: -57px;
                            right: unset;
                            left: 0;
                        }
                    }
                    button.share {
                        position: relative;
                        z-index: 2;
                    }
                }
                .group {
                    margin-bottom: 49px;
                    @include mobile {
                        margin-bottom: 30px;
                    }
                    &.mobile {
                        display: none;
                        @include mobile {
                            display: block;
                        }
                    }
                    .role {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(75, 75, 75);
                        // opacity: 50%;
                    }
                    &.author {
                        h2 {
                            margin-bottom: 3px;
                        }
                    }
                    h2 {
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        margin-bottom: 5px;
                    }
                }
                .indent {
                    padding-left: 32px;
                    margin-top: 18px;
                    border-left: 2px solid var(--inactive-button);
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 0em;
                    &.capitalize {
                        text-transform: capitalize;
                        // font-weight: bold;
                    }
                }
            }
        }
    }
    section.more-recipes {
        background-color: #000;
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
        @include mobile {
            padding-top: 30px;
        }
        .container {
            position: relative;
            .stars {
                @include mobile {
                    display: none;
                }
            }
            .title {
                h2 {
                    text-align: center;
                    color: white;
                    font-size: 32px;
                    line-height: 42px;
                    margin-top: 0;
                    margin-bottom: 40px;
                    @include mobile {
                        margin-bottom: 20px;
                    }
                }
            }
            .cards {
                justify-content: center;
                flex-wrap: wrap;
                display: flex;
                gap: 20px;
                padding-bottom: 40px;
            }
            .button-wrap {
                text-align: center;
                display: flex;
                justify-content: center;
                @include mobile {
                    margin-left: auto;
                    margin-right: auto;
                }
                .active {
                    color: black;
                }
            }
        }
    }
}