@import '../../variables.scss';
header{
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  position: absolute;
  top:0;
  left: 50%;
  transform:translateX(-50%);
  z-index: 10;
  &.nav-up,
  &.nav-down{
    position: fixed;
    .nav-wraper{
      position: fixed;
      top:0px;
      width: 100%;
      transition: all ease .25s;
      z-index: 6;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1300px;
      &:after{
        background-color: $color-black;
        width: 200vw;
        left: -50%;
        height: 110%;
        content: "";
        position: absolute;
        z-index: -1;
        top:-10%;
        transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) .5s 0s;
      }
    }
    &.top{
      .nav-wraper{
        top:0px;
        background-color: transparent;
        &:after{
          //top:-90px;
          transition: cubic-bezier(0.68, -0.55, 0.265, 1.55) .45s 0s;
        }
      }
    }
  }
  &.nav-up{
    .nav-wraper{
      top:-100px;
      .text-nav{
        opacity: 0;
      }
    }
    &.top{
      .nav-wraper{
        top:-100px;
        &:after{
        }
      }
    }
  }
  .mainNav{
    float: right;
    padding: 25px 0px 25px;
    a{
      margin-left: 25px;
      color:$color-black;
      text-decoration: none;
      transition: all ease .25s;
      &:hover{
        color:$color-green;
      }
    }
    .text-nav{
      top:0px;
      transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) .5s 0s;
      position: relative;
      color: $color-white;
      @include font-medium;
      font-size: 12px;
      letter-spacing: .25px;
      @include tablet{
        display: none;
      }
      @include mobile{
        display: none;
      }
    }
    .hamburger{
      display: inline-block;
      border: none;
      margin-left: 50px;
      background-color: transparent;
      width: 21px;
      height: 20px;
      vertical-align: top;
      position: relative;
      z-index: 20;
      cursor: pointer;
      &:focus{
        outline:none;
      }
      span{
        position: absolute;
        display: block;
        width: 70%;
        height: 3px;
        background: $background-gradient;
        left: 0px;
        top: 0px;
        border-radius: 20px;
        transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) .25s;
        &:nth-of-type(1){
          left: 30%;
          &:before{
            content:'';
            width:3px;
            height: 3px;
            display: inline-block;
            background-color: $color-green;
            position: absolute;
            border-radius: 100%;
            left: -40%;
            transition: all ease .25s;
          }
        }
        &:nth-of-type(2),
        &:nth-of-type(4){
          top:7px;
          width: 100%;
        }
        &:nth-of-type(3){
          top: 14px;
          right: 30%;
          left: inherit;
          &:after{
            content:'';
            width:3px;
            height: 3px;
            display: inline-block;
            background-color: $color-blue;
            position: absolute;
            border-radius: 100%;
            right: -40%;
            transition: all ease .25s;
          }
        }
      }
      &.open{
        span{
          transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) .25s;
          width: 100%;
          &:nth-of-type(1),
          &:nth-of-type(3){
            width: 0%;
            &:after,
            &:before{
              opacity: 0;
            }
          }
          &:nth-of-type(2){
            transform: rotate(45deg);
            transition-delay: .25s;
          }
          &:nth-of-type(4){
            transition-delay: .25s;
            transform: rotate(-45deg);
          }
        }
      }
    }
    &.active{
      .text-nav{
        top:0px;
      }
    }
  }
  nav{
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100vh;
    color: $color-black;
    z-index: 5;
    text-align: left;
    transform: translateX(-50%);
    ul, li{
      list-style: none;
      padding-left: 0px;
      text-indent: 0px;
      padding: 0px;
      &:before{
        display: none;
      }
    }
    .nav-wrap{
      z-index: 5;
      opacity: 1;
      position: relative;
      transition: all ease .5s .5s;
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      max-width: 900px;
      @include mobile{
        width: 80%;
      }
      .large-nav{
        display: inline-block;
        width: 40%;
        vertical-align: top;
        @include tablet{
          width: 100%;
          text-align: center;
        }
        @include mobile{
          width: 100%;
          text-align: center;
        }
        ul{
          margin-top: 0px;
        }
        li{
          opacity: 1;
          transition: all ease .25s;
          &:nth-of-type(1){
            transition-delay:.25s;
          }
          &:nth-of-type(2){
            transition-delay:.30s;
          }
          &:nth-of-type(3){
            transition-delay:.35s;
          }
          &:nth-of-type(4){
            transition-delay:.4s;
          }
          &:nth-of-type(5){
            transition-delay:.45s;
          }
          &:nth-of-type(6){
            transition-delay:.5s;
          }
          &:nth-of-type(7){
            transition-delay:.55s;
          }
        }
        a{
          color:$color-white;
          font-size: 38px;
          text-decoration: none;
          line-height: 80px;
          transition: all ease .25s;
          position: relative;
          @include font-bold;
          &:before{
            content: "";
            opacity: 0;
            transition: all ease .25s;
            position: absolute;
            display: inline-block;
            width: 5px;
            height: 40px;
            background-image: url(../../../public/img/footer-accent.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 20%;
            left: -45px;
          }
          @include tablet{
            line-height: 47px;
            text-align: center;
          }
          @include mobile{
            line-height: 47px;
            text-align: center;
          }
          &:hover{

            &:before{
              opacity: 1;
              transform: translateX(15px);
            }
          }
          &.is-active{

            &:before{
              opacity: 1;
              transform: translateX(15px);
            }
          }
        }
      }
      .small-nav{
        margin-bottom: 60px;
        display: inline-block;
        width: 25%;
        vertical-align: top;
        @include tablet{
          width:100%;
          text-align: center;
          margin-bottom: 0px;
        }
        @include mobile{
          width:100%;
          text-align: center;
          margin-bottom: 0px;
        }
        li{
          opacity: 1;
          transition: all ease .25s;
          &:nth-of-type(1){
            transition-delay:.40s;
          }
          &:nth-of-type(2){
            transition-delay:.45s;
          }
          &:nth-of-type(3){
            transition-delay:.50s;
          }
          &:nth-of-type(4){
            transition-delay:.55s;
          }
          &:nth-of-type(5){
            transition-delay:.6s;
          }
          &:nth-of-type(6){
            transition-delay:.65s;
          }
        }
        a{
          color:$color-white;
          font-size: 24px;
          text-decoration: none;
          line-height: 55px;
          transition: all ease .25s;
          position: relative;
          &:before{
            content: "";
            opacity: 0;
            -webkit-transition: all ease .25s;
            -o-transition: all ease .25s;
            transition: all ease .25s;
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 25px;
            background-image: url(../../../public/img/footer-accent.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 15%;
            left: -45px;
          }
          @include tablet{
            font-size: 20px;
            line-height: 30px;
          }
          @include mobile{
            font-size: 20px;
            line-height: 30px;
          }
          &:hover{

            &:before{
              opacity: 1;
              transform: translateX(15px);
            }
          }
          &.is-active{

            &:before{
              opacity: 1;
              transform: translateX(15px);
            }
          }
        }
      }
      .social-links{
        opacity: 1;
        transition: all ease .25s .25s;
        text-align: left;
        float: none;
        margin: 0 auto;
        display: inline-block;
        width: 25%;
        vertical-align: top;
        margin-left: 10%;
        @include tablet{
          display: none;
          width: 100%;
          margin: 0px;
        }
        @include mobile{
          display: none;
          width: 100%;
          margin: 0px;
        }
        span{
          color: $color-white;
          font-size: 16px;
          @include font-book;
          display: block;
          text-align: left;
          margin: 30px 0px 10px;
          &:after{
            content:'';
            width: 4px;
            height: 4px;
            margin-left: 5px;
            background-color: $color-red;
            display: inline-block;
            position: relative;
          }
        }
        a{
          display: inline-block;
          position: relative;
          text-align: left;
          margin-left: 0px;
          font-size: 16px;
          line-height: 45px;
          transition: all ease .25s;
          background:$background-gradient;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          @include font-bold;
          @include tablet{
            display: none;
          }
          @include mobile{
            display: none;
          }
          &:before{
            content: "";
            opacity: 0;
            -webkit-transition: all ease .25s;
            -o-transition: all ease .25s;
            transition: all ease .25s;
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 20px;
            background-image: url(../../../public/img/footer-accent.svg);
            background-repeat: no-repeat;
            background-size: contain;
            top: 25%;
            left: -45px;
          }
          &:hover{

            &:before{
              opacity: 1;
              transform: translateX(15px);
            }
          }
        }
        .logo{
          opacity: 1;
          margin-bottom: 30px;
          position: relative;
          transition: all ease .25s;
          @include tablet{
            display: block;
            width: 100%;
            text-align: center;
          }
          @include mobile{
            display: block;
            width: 100%;
            text-align: center;
          }
          &:after{
            opacity: 1;
            @include tablet{
              left: 50%;
              transform: translateX(-50%);
            }
            @include mobile{
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &:hover{
            opacity: 1;
            &:after{
              opacity: .65;
            }
            &:before{
              display: none;
            }
          }
        }
      }
      .button{
        box-sizing: border-box;
        opacity: 1;
        transition: all ease .25s .60s, background ease .25s 0s;
      }
      &.lock{
        pointer-events: none;
      }
    }
    &.closed{
      width: 0%;
      transition-delay: .5s;
      z-index:-20;
      left: 100vw;
      &:after, &:before{
        transition: all ease .5s .25s;
        opacity: 0;
      }
      .nav-wrap{
        li{
          opacity: 0;
          transition: all ease .25s;
          transform: translateY(20px);
          &:nth-of-type(n){
            transition-delay:0s;
          }
        }
        .social-links, .button{
          opacity: 0;
          transform: translateY(20px);
          transition-delay: 0s;
        }
      }
    }
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      z-index: -1;
      transition: all ease .55s 0s;
      top:0px;
      background-color: $color-black;
    }
  }
  .logo{
    display: inline-block;
    position: relative;
    z-index: 20;
    transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) .5s -.25s, color ease .5s 0s;
    top:0px;
    &:after{
      content: "";
      width: 230px;
      background-image: url(../../../public/img/grit-logo.svg);
      background-repeat: no-repeat;
      background-position: center left;
      height: 35px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0px;
      top: 5px;
      opacity: 1;
      transition: all ease .5s;
    }
    &.active{
      top:0px !important;
    }
  }
  .hamburger{
    &.lock{
      pointer-events: none;
    }
  }
}

.open header{
  position: fixed;
  .logo{
    color:transparent;
    &:before{
      opacity: 0;
    }
    &:after{
    }
  }
  .nav-wraper{
    &:after{
    }
  }
}
