@import './variables.scss';
#recipes {
    background-color: #171615;
    // min-height: 100vh;
    header {
        background: $creme;
        position: relative;
        .container {
            position: relative;
        }
        .navigation {
            @include mobile {
                justify-content: center;
            }
            .share-wrap {
                @include mobile {
                    margin-right: 0;
                }
            }
        }
        @include mobile {
            padding-bottom: 60px;
        }
    }
    main {
        padding-top: 59px;
        @include mobile {
            padding-top: 30px;
        }
        .filter {
            display: flex;
            justify-content: center;
            gap: 12px;
            flex-wrap: wrap;
            padding-bottom: 59px;
            position: relative;
            z-index: 4;
            .box {
                box-shadow: 6px 6px 1px 0px #0000003B;
                // background: #0F0C0A;
                &.active,
                &:hover {
                    color: #000;
                }
            }
            @include mobile {
                padding-bottom: 40px;
            }
        }
        .recipes-index {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 20px;
            padding-bottom: 40px;
            // transition: all 0.2s ease;
            &.hide {
                opacity: 0;
                transform: translateY(15px);
                transition: opacity ease .3s, transform cubic-bezier(0.34, 1.56, 0.64, 1) .3s;
            }
            &.show {
                opacity: 1;
                transition: opacity ease .3s, transform cubic-bezier(0.34, 1.56, 0.64, 1) .3s;
                transform: translateY(0px);
            }
            .recipe-card {
                border-radius: 4px;
                box-shadow: 6px 6px 1px 0px #0000003B;
                @include mobile {
                    box-shadow: none;
                }
            }
        }
        .stars {
            &.five,
            &.six {
                @include mobile {
                    display: none;
                }
            }
        }
    }
    section.bottom {
        margin-bottom: 150px;
        position: relative;
        @include mobile {
            margin-bottom: 80px;
        }
        .container {
            display: flex;
            justify-content: center;
            button {
                max-width: 420px;
                &.active {
                    color: black;
                }
            }
        }
        .fade {
            position: absolute;
            height: 430px;
            background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 22, 21, 1) 77%);
            width: 100%;
            top: -430px;
            pointer-events: none;
            // mix-blend-mode: pass;
        }
    }
}