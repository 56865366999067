@import '../../variables.scss';

.related-projects{

  padding: 5vh 0px;
  width:100vw;
  overflow: hidden;
  @include mobile{
    width:100vw;
    overflow: hidden;
  }
  h2{
    font-size: 42px;
    margin-bottom: 0px;
    @include mobile{
      font-size: 25px;
    }
  }
  .slick-list,.slick-slider,.slick-track{
      position:relative;
      display:block
  }
  .slick-loading .slick-slide,.slick-loading .slick-track{
      visibility:hidden
  }
  .slide-title{
    position: absolute;
    top:100%;
    width: 100%;
    text-align: center;
    h3{

      font-size: 22px;
      color: $color-black;
      margin-bottom: 0px;
    }
    h4{
      text-transform: none;
      font-size: 18px;
      margin-top: 5px;
      @include mobile{
        font-size: 16px;
      }
    }
  }
  .wrapper{
    position: relative;
  }
  .slick-slider{
      box-sizing:border-box;
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
      -webkit-touch-callout:none;
      -khtml-user-select:none;
      -ms-touch-action:pan-y;
      touch-action:pan-y;
      -webkit-tap-highlight-color:transparent;
      position: relative;
      left:0%;
      width: 100%;
      max-width: 1100px;
      margin: 3% 0px 10vh;
      @include mobile{
        left: 0%;
        width: 90%;
        margin: 2vh auto 10vh;
      }
      .slick-arrow{
        border: none;
        color:transparent;
        border-radius:0px;
        height: 60px;
        width: 60px;
        border-radius: 10px;
        background-color: $color-red;
        position: absolute;
        top:50%;
        transform:translateY(-50%) rotate(-180deg);

        left: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all ease .25s;
        &:hover{
          &:before{
          }
        }
        &:before{
          content:"";
          width: 20px;
          height: 10px;
          background-image: url(../../../public/img/arrow-white.svg);
          background-size: contain;
          position: absolute;
          display: block;
          left: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          top: 50%;
        }
        &.slick-next{
            left: 100% !important;
            left: inherit;
            transform:translateY(-50%) rotate(0deg);
            @include mobile{
              display: none !important;
            }
        }
        &.slick-prev{
          left: -5.5%;
          @include mobile{
            display: none !important;
          }
        }
      }
      .slide{
        padding: 0px 5%;
        box-sizing: border-box;
        max-height: 30vw;
        overflow: hidden;
        position: relative;
        @include tablet{
          max-height: 40vw;
        }
        @include mobile{
          max-height: 100vw;
          padding: 0px 3%;
        }
        .type{
          position: absolute;
          top:2vw;
          &.h4{
            margin: 0px;
          }
          @media all and (max-width: 1000px){
            top:0vw;
          }
          @include mobile{
            display: none;
          }
        }
        h4{
          color: $color-white;
          font-size: 16px;
          left: 12%;
        }
        .content{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 12%;
          max-width: 75%;
          h2{
            color: $color-white;
            margin-top: 0vw;
            line-height: 50px;
          }
          h4{
            left: 0px;
          }
          .text-link{
            &:after{
              background-image: url(../../../public/img/arrow-white.svg);
              transform: rotate(0deg) translateY(10px);
            }
          }
        }
        .number-wrap{
          display: none;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background-color: $color-white;
          padding: 5px 10px 5px 10px;
          @include mobile{
            display: none;
          }
          .count{
            color: $color-black;
            display: inline-block;
            padding-right: 6px;
            font-size: 25px;

          }
          .total{
            color: $color-black;
            display: inline-block;

            font-size: 18px;
            span{
              font-size: 25px;
            }
          }
        }
      }
  }
  .slick-list{
      overflow:visible;
      margin:0;
      padding:0;
      transition: height ease .5s;
  }
  .slick-list:focus{
      outline:0
  }
  .slick-list.dragging{
      cursor:pointer;
      cursor:hand
  }
  .slick-slider .slick-list,.slick-slider .slick-track{
      -webkit-transform:translate3d(0,0,0);
      -moz-transform:translate3d(0,0,0);
      -ms-transform:translate3d(0,0,0);
      -o-transform:translate3d(0,0,0);
      transform:translate3d(0,0,0)
  }
  .slick-track{
      top:0;
      left:0
  }
  .slick-track:after,.slick-track:before{
      display:table;
      content:''
  }
  .slick-track:after{
      clear:both
  }
  .slick-slide{
      display:none;
      float:left;
      height:100%;
      min-height:1px
  }
  [dir=rtl] .slick-slide{
      float:right
  }
  .slick-slide img{
      display:block;
      min-width:100%;
  }
  .slick-slide.slick-loading img{
      display:none
  }
  .slick-slide.dragging img{
      pointer-events:none
  }
  .slick-initialized .slick-slide{
      display:block
  }
  .slick-vertical .slick-slide{
      display:block;
      height:auto;
      border:1px solid transparent
  }
  .slick-arrow.slick-hidden{
      display:none
  }
  .slick-dots{
    z-index: 1;
    display: block;
    background-color:$color-white;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    li, ul{
      list-style: none;
      padding-left: 0px;
      text-indent: 0px;
      position: relative;
      color:$color-black;

      font-size: 14px;
      &:before{
        display: none;
      }
    }
    li{
      display: none;
      &.slick-active{
        display: block;
      }
    }
  }
  &.single{
    h2{
      text-align: center;
    }
    .slick-track{
      margin: 0px auto !important;
    }
    .number-wrap{
      display: none;
    }
  }
}
/*# sourceMappingURL=slick.min.css.map */
