@import "../../variables.scss";

.marketing-toolkit {
  &.pagewrap {
    margin-top: 100px;
  }
  @include mobile {
    padding-top: 0px;
  }
  h1 {
    @include font-bold;
    font-size: 40px;
    line-height: 50px;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after {
      content: "";
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #eb1946;
      display: inline-block;
      vertical-align: middle;
    }

    @include mobile {
      font-size: 32px;
    }
  }
  .crumb-link {
    @include gradient-color;
    @include font-bold;
    span {
      font-size: 15px;
    }

    @include mobile {
      display: none;
    }

    @include tablet {
      display: none;
    }
  }

  .copy-block {
    &.text-content {
      margin: 20vh 0px;
    }
    .copy-wrap.intro {
      margin: 0 auto;
      max-width: 1300px;
      text-align: left;
      position: relative;

      .left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        @include tablet {
          width: 100%;
        }
        @include mobile {
          width: 100%;
        }
        h1 {
          @include font-bold;
          color: $color-white;
          margin-bottom: 80px;
          margin-top: 0px;
          &:after {
            content: "";
            margin-left: 10px;
            margin-top: 5px;
            width: 8px;
            height: 8px;
            background-color: #eb1946;
            display: inline-block;
            vertical-align: middle;
          }
        }
        h3 {
          color: $color-white;
          width: 70%;
          position: relative;
          @include font-book;
          @include tablet {
            width: 100%;
            font-size: 24px;
          }
          @include mobile {
            width: 100%;
            font-size: 24px;
          }
          &:before {
            content: "";
            background: $background-gradient;
            height: 4px;
            width: 20px;
            display: block;
            margin-top: -40px;
            position: relative;
            margin-bottom: 20px;
          }
        }
      }
      .right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        @include tablet {
          width: 100%;
        }
        @include mobile {
          width: 100%;
        }
        p {
          color: $color-white;
          margin-top: 110px;
          @include tablet {
            margin-top: 20px;
          }
          @include mobile {
            margin-top: 0px;
          }
        }
      }
    }
    @include mobile {
      margin: 0px auto 80px;
    }
  }

  .subhead {
    display: flex;

    .box {
      flex: 1;
    }

    .subhead-title {
      position: relative;
      @include turq-line;
    }
  }

  .quicklinks {
    background-color: $color-white;
    padding: 40px 0 30px;
    p {
      position: relative;
      @include turq-line;
      font-size: 24px;
      text-transform: none;
    }
    .quicklink {
      margin-bottom: 20px;

      a {
        @include font-bold;
        @include red-arrow;
        color: #2a2a23;
        position: relative;
        letter-spacing: 0.9px;

        @include mobile {
          letter-spacing: 0;
        }
      }
    }
  }

  .sections {
    background-color: $color-white;

    .section {
      background-color: $color-lightgrey;
      padding: 71px 56px 71px 69px;
      border-radius: 20px;
      box-sizing: border-box;
      margin-bottom: 56px;

      &:last-child {
        margin-bottom: 0;
      }

      @include tablet {
        padding: 56px 56px;
      }

      @include mobile {
        padding: 36px 36px;
      }

      h3 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 20px;
        margin-top: 0;

        @include small-desktop {
          font-size: 32px;
          line-height: 32px;
        }

        @include tablet {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 32px;
        }

        @include mobile {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 32px;
        }
      }

      .assets {
        .asset {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 64px;

          &:last-child {
            margin-bottom: 0;
          }

          .asset-text {
            flex: 0.82;

            @include tablet {
              order: 2;
              flex: 0 0 100%;
            }

            @include mobile {
              order: 2;
              flex: 0 0 100%;
            }

            h3 {
              font-size: 20px;
              line-height: 35px;
              margin-bottom: 0px;
            }

            p {
              margin-top: 0;
              padding-right: 64px;
              color: #2a2a23;

              @include small-desktop {
                font-size: 18px;
                line-height: 28px;
              }

              @include tablet {
                font-size: 18px;
                line-height: 28px;
                padding-right: 0;
              }

              @include mobile {
                font-size: 18px;
                line-height: 28px;
                padding-right: 0;
              }
            }

            a {
              position: relative;
              @include font-bold;
              @include red-arrow;
              letter-spacing: 0.9px;

              font-size: 16px;
              color: #2a2a23;

              @include small-desktop {
                font-size: 14px;
              }
            }
          }

          .asset-img {
            flex: 0.18;

            @include small-desktop {
              flex: 0.25;
            }

            @include tablet {
              order: 1;
              flex: 0 0 100%;
              margin-bottom: 32px;
              max-width: 250px;
            }

            @include mobile {
              order: 1;
              flex: 0 0 100%;
              margin-bottom: 32px;
              max-width: 250px;
            }
          }
        }
      }
    }
  }

  .fill {
    background-color: $color-white;
    height: 143px;
    width: 100%;
  }
  .image-fill {
    background-color: red;
    height: 210px;
    width: 100%;
  }

  .fade-in-element {
    &.team_member {
      transition: all ease 0.5s 0.15s;
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
