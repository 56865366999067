@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
$orange: #d43500;
$creme: #f1eee8;
$gold: #e5d0a2;
$lime: #d9e024;
$orange: #ef6b3b;
$black: #333333;
$grey: #f1eee8;
$dark-grey: #595857;
$med-grey: #d6d3cd;
$white: #fffbf4;
$dark-blue: #084b8a;
$blue: #3570bc;
$yellow: #e08f33;
$sandal: #fffbf4;
:root {
  --active-button: #f1eee8;
  --inactive-button: #2d2b2a;
  --medium-font: 'Montserrat', sans-serif;
  --bold-font: 'Montserrat', sans-serif;
  --playfair-font: 'Playfair Display', serif;
  --base-font: 'Montserrat', sans-serif;
}

@mixin mobile {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin bpDown($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}

@mixin bpUp($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}

%subhead-two {
  font-family: var(--bold-font);
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0em;
}
