@import '../../variables.scss';

#team{
  &.pagewrap{
    margin-top: 100px;
  }
  @include mobile{
    padding-top: 0px;
  }
  h1{
    @include font-bold;
    font-size: 40px;
    line-height: 50px;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  #team_members{
    background-color: $color-white;
    padding: 10vh;
    @include desktop{
      padding: 5%;
    }
    @include tablet{
      padding: 5%;
    }
    @include mobile{
      padding: 5%;
    }
  }
  .team_member{
    h2{
      @include mobile{
        margin: 15px 0px 0px;
      }
    }
    h4{
      @include mobile{
        margin-top: 10px;
      }
    }
  }
  .intro-copy{
    padding-bottom: 40px;
    @include mobile{
      width:100%;
      margin-bottom: 30px;
    }
    h3{
      @include font-book;
      color: $color-white;
      &:before{
        content:'';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top:-40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
  }
  .small{
    width: 22.7%;
    display: inline-block;
    vertical-align: top;
    margin: 0vh 3% 7vh 0px;
    @include tablet{
      width:45%;
      margin-right: 10%;
      &:nth-of-type(2n){
        margin-right: 0px !important;
      }
    }
    @include mobile{
      width:100%;
      margin: 4vh 0% 4vh 0px;
      &:nth-of-type(2n){
        margin-right: 0px !important;
      }
    }
    &:hover{
      img{
        transform: scale(1.025);
      }
      &:after{
        transform: translateX(10px);
      }
      .main-img{
        img{
          -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
          filter: grayscale(0%);
        }
      }
    }
    &:after{
      content:"";
      background-image: url(../../../public/img/arrow-red.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 10px;
      display: inline-block;
      margin-left: 0px;
      z-index: 1;
      position: relative;
      transition: all ease .25s;
      position: absolute;
      bottom: 0px;
      left: 0px;
      @include mobile{
        margin-top: 6px;
      }
    }
    .main-img{
      cursor: pointer;
      overflow: hidden;
      height: 0px;
      width: 100%;
      padding-bottom: 98%;
      position: relative;
      img{
        transition: all ease .5s;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
      }
      .container{
        width:10%;
        height:5%;
        max-width: 20px;
        max-height: 15px;
        position:absolute;
        display:block;
        top: 92%;
        left: 85%;
        z-index: 4;
        transition: all ease .25s .0s;
        .case-study-link{
          margin-top: 0px;
          opacity: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    h2{
      font-size: 26px;
      line-height: 26px;
      text-align: left;
      margin-bottom: 8px;
    }
    h4{
      text-align: left;
      margin-top: 0px;
      font-size: 16px;
      line-height: 18px;
      text-transform: none;

    }
    &:nth-of-type(4n){
      margin-right: 0px;
      @include tablet{
        margin-right: 10%;
      }
      @include mobile{
        margin-right: 2%;
      }
    }
  }
  .about-cards{
    padding: 10vh 0px;
    margin-bottom: 20vh;
  }
  .hidden{
    &.team_member{
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .fade-in-element{
    &.team_member{
      transition:  all ease .5s .15s;
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
