@import '../../variables.scss';
.button{
    color: $color-white;
    text-transform: uppercase;
    text-decoration: none;
    padding:15px;
    text-align: center;
    max-width: 400px;
    transition: all ease .25s;
    position: relative;
    font-size: 14px;
    cursor: pointer;
    background: $background-gradient;
    border-radius: 10px;
    font-size: 14px;
    box-sizing: border-box;
    @include font-bold;
    &:hover{

    }
    input{
      color: $color-white;
      font-size: 14px;
      @include font-bold;
      padding: 0px;
      background-color: transparent;
      border: none;
    }
    @include mobile{
      width:100%;
      display: block;
    }
}

.text-link{
  font-size: 16px;
  padding: 0px 10px;
  border: none;
  background: transparent;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  margin-right: 40px;
  height: 28px;
  transition: all ease .25s .0s;
  margin-bottom: 30px;
  @include font-bold;
  letter-spacing: 1px;
  background:$background-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      color: $color-blue;
      background: transparent;
  }
  @include mobile{
      font-size: 15px;
      padding: 0 5px;
      height: 20px;
  }
  span{
    position: relative;
    z-index: 1;
  }
  &:after{
    content:"";
    background-image: url('../../../public/img/arrow-red.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 10px;
    display: inline-block;
    margin-left: 10px;
    z-index: 1;
    position: relative;
    transition: all ease .25s;
    position: absolute;
    top: 5px;
    @include mobile{
      margin-top: 2px;
    }
  }
  &:hover{
    &:after{
      transform: translateX(5px);
    }
  }
  &.back{
    &:before{
      transform: rotate(180deg);
      margin-top: 6px;
    }
  }
  &.black{
    letter-spacing: .5px;
    padding-left: 0px;
    color: $color-black;
    background: none;
    -webkit-background-clip: none;
    background-clip: none;
    -webkit-text-fill-color: currentcolor;
    text-fill-color: currentcolor;
  }
}
button.text-link{
  margin-top: -1.5px;
  &:after{
    bottom:-8.5px;
  }
}

.case-study-link{
  width: 20px;
  height: 10px;
  background-image: url('../../../public/img/arrow-black.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  display: block;
  margin-top: 40px;
  cursor: pointer;
  transition: all ease .25s;
}
