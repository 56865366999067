@import '../../variables.scss';

#app.home{
  width: 100vw;
  height: auto;
  position: relative;
  header.nav-up .nav-wraper::after,
  header.nav-down .nav-wraper::after{
    box-shadow: none;
  }
}
#home{
  position: relative;
  display: block;
  width: 100%;
  section{
    position: relative;
    transition: all ease .25s .25s;
    width: 100%;
    box-sizing: border-box;
    &.hero{
      background-color: $color-black;
      background-image: url(../../../public/img/background-pattern.png);
      z-index: 0;
      min-height: 100vh;
      .text-wrap{
        display: block;
        width: 70vw;
        position: absolute;
        top:50%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        @include mobile{
          width:90%;
        }
        &:hover{
          h1{
            span{
              &:after{
                width:100%;
                transition-delay: 0s;
              }
            }
            &:nth-of-type(2){
              span{
                &:after{
                  transition-delay: .20s;
                }
              }
            }
          }
        }
      }
      h1{
        width:100%;
        margin: 0 auto;
        background-color: $color-black;
        color: $color-white;
        display: inline;
        padding: 1vw;
        line-height:4.5vw;
        font-size: 3.75vw;
        z-index: 5;
        position: relative;
        span{
          display: inline-block;
          position: relative;
          background:$background-gradient;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          @media all and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
              /* IE10+ CSS styles go here */
              color: $color-blue;
              background: transparent;
          }
          &:after{
            content:'';
            transition: all ease .25s .2s;
            width:0%;
            height: 5px;
            background-color: $color-red;
            position: absolute;
            bottom:0px;
            left: 0px;
          }
        }
        &:nth-of-type(2){
          z-index: 4;
          span{
            &:after{
              transition-delay: 0s;
            }
          }
        }
        &:nth-of-type(3){
          z-index: 3;
        }
        &:nth-of-type(4){
          z-index: 2;
        }
        @include tablet{
          line-height:6.5vw;
          font-size: 5.75vw;
        }
        @include mobile{
          line-height: 9vw;
          font-size: 7vw;
        }
      }
      br{
        @include tablet{
          display: none;
        }
        @include mobile{
          display: none;
        }
      }
      .rotator-wrap{
        width:40%;
        max-width: 700px;
        height: 80%;
        position: absolute;
        right: 10%;
        top:12.5%;
        z-index: 0;
        box-sizing: border-box;
        border-radius: 10px;
        transition: all ease .5s;
        &.hide{
          pointer-events: none;
          opacity: 0;
        }
        &.show{
          opacity: 1;
        }
        @include tablet{
          display: none;
        }
        @include mobile{
          display: none;
        }
      }
      .rotator{
        width:100%;
        height: 100%;
        overflow: hidden;
        transition: all ease .5s;
        position: relative;
        display: block;
        border-radius: 10px;
      }
      .rotatorText{
        position: absolute;
        color: $color-white;
        @include font-bold;
        bottom:15%;
        transform: rotate(-90deg);
        left: -70px;
      }
    }
  }
  .client-experience{
    background-color: #f7f7f7;
    padding: 15vh 0px;
    height: auto;
    text-align: center;
    @include tablet{
      padding: 60px 0px;
      box-sizing: content-box;
      height: auto;
      overflow: hidden;
      min-height: auto;
    }
    @include mobile{
      padding: 60px 0px;
      box-sizing: content-box;
      height: auto;
      overflow: hidden;
      min-height: auto;
    }
    .clear{
      clear: both;
      float: none;
    }
  }
  .client-logos-wrap,
  .client-content{
    display: block;
    vertical-align: middle;
    text-align: center;
    @include tablet{
      width:95%;
    }
    @include mobile{
      width:95%;
    }
  }
  .client-content{
    padding-left: 0%;
    width:75%;
    max-width: 650px;
    margin:  0 auto;
    box-sizing: border-box;
    @include tablet{
      float: none;
      width: 100%;
      margin-bottom: 20px;
    }
    @include mobile{
      float: none;
      width: 100%;
    }
    h2{
      font-size: 50px;
      @include font-bold;
      margin-top: 0px;
      @include mobile{
        font-size: 25px;
      }
      &:after{
        content:'';
        margin-left: 7px;
        margin-top: 6px;
        width: 5px;
        height: 5px;
        background-color: $color-red;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .text-link{
      white-space: nowrap;
    }
  }
  .client-logos{
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 40px;
    @include tablet{
      float: none;
      width:100%;
      margin-left: 0%;
    }
    @include mobile{
      float: none;
      width:100%;
      margin-left: 0%;
    }
    .logo{
      width: 20%;
      display: inline-block;
      height: 0px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      vertical-align: top;
      position: relative;
      margin: -1px;
      padding-bottom: 10%;
      @include mobile{
        height: 10vh;
      }
      .image{
        max-width: 150px;
        min-width: 50px;
        max-height: 150px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .featured-posts{
    padding:10vh 0px;
    .posts{
      clear:both;
    }
    .content-wrap{
      //animation:fadeOut .25s ease forwards;
    }
    h2{
      margin-bottom: 10vh;
      float: left;
      @include desktop{
        font-size: 45px;
      }
      @include tablet{
        font-size: 35px;
        width: 100%;
        margin-bottom: 5vh;
      }
      @include mobile{
        margin-bottom: 2vh;
        font-size: 30px;
        line-height: 32px;
        width: 100%;

      }
    }
    .news-card{
      width: 31%;
      display: inline-block;
      vertical-align: top;
      margin-right: 3.5%;
      position: relative;
      transform: translateY(10px);
      transition: all ease .25s .5s;
      &:nth-of-type(2){
        transition-delay: .65s;
      }
      &:last-of-type{
        margin-right: 0px;
        transition-delay: .75s;
      }
      @include tablet{
        width: 100%;
        margin-bottom: 2vh;
      }
      @include mobile{
        width: 100%;
        margin-bottom: 2vh;
      }
      .image-wrap{
        height: 200px;
        width: 100%;
        overflow: hidden;
        position: relative;
        @media all and (max-width: 768px){
          width: 30%;
          float: left;
          height: 18vh;
          min-height: 60px;
          max-height: 135px;
        }
        img{
          position: absolute;
          top:50%;
          transform: translateY(-50%);
        }
        @include desktop{
          max-height: 35vh;
        }
      }
      h3{
        color:$color-black;
        font-size: 31px;
        padding: 0px 8%;
        @media all and (max-height: 750px){
            width: 80%;
            float: left;
            padding: 0px;
            margin-top: 2vh;
        }
        @media all and (max-width: 768px){
            width: 80%;
            float: left;
            padding: 0px;
            margin-top: 2vh;
        }
        @include desktop{
          padding: 0px;
        }
        @include tablet{
          font-size: 25px;
          width: 50%;
          position: absolute;
          left: 34%;
          top: 50%;
          transform: translateY(-50%);
          margin: 0px;
          padding: 0px;
        }
        @include mobile{
          font-size: 25px;
          width: 50%;
          position: absolute;
          left: 34%;
          top: 50%;
          transform: translateY(-50%);
          margin: 0px;
          padding: 0px;
        }
      }
      p{

        color: $color-black;
        font-size: 20px;
        padding: 0px 8%;
        max-height: 145px;
        overflow: hidden;
        @media all and (max-height: 750px){
          display: none;
        }
        @media all and (max-width: 800px) and (max-height:3500px){
          display: none;
        }
        @include desktop{
          padding: 0px;
        }
        @include tablet{
          padding: 0px;
        }
        @include mobile{
          padding: 0px;
        }
      }
      .button{
        margin: 8%;
      }
    }
  }
  @keyframes bounce{
    0%, 55%, 65%, 100%{transform: translateY(0px);}
    60%{transform: translateY(5px);}
  }
  .hidden{
      .intro-copy, .caps-slide, .client-logos-wrap, .client-content{
        opacity: 0;
        transform: translateY(20px);
      }
      &.workCallout{
        opacity: 0;
        transform: translateY(20px);
      }
  }
  .fade-in-element{
    .cap-wrap{
      .intro-copy{
        opacity: 1;
        transform: translateY(0px);
        transition: all ease .75s .15s;
      }
      .caps-slide{
        opacity: 1;
        transform: translateY(0px);
        transition: all ease .75s .45s;
      }
    }
    .client-logos-wrap{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s .55s;
    }
    .client-content{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s .25s;
    }
    &.workCallout{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s .15s;
    }
  }
  .workCallout{
    text-align: center;
    padding: 40px 0px;
    background-color: $color-darkgrey;
    p{
      margin: 0px auto 20px;
      font-size: 32px;
      line-height: 42px;
      max-width: 500px;
      display: block;
      color: $color-white;
    }
    .button{
      display: inline-block;
    }
    @include mobile{
      display: none;
    }
  }
}
