@import '../../variables.scss';

#Capabilities{
  padding-top: 150px;
  @include mobile{
    padding-top: 100px;
  }
  .crumb{
    @include gradient-color;
    @include font-bold;
  }
  h1{
    margin-bottom: 100px;
    margin-top: 0px;
    color: $color-white;
    @include mobile{
      margin-bottom: 35px;
    }
  }
  .hero-img{
    width:100%;
    img{
      width: 100%;
    }
  }
  .capabilities_list{
    background-color: $color-white;
    padding-top: 80px;
  }
  .capability-card{
    @include tablet{
        width: 49%;
        &:nth-of-type(2n){
          margin-right: 0px;
        }
    }
    .support-img{
      margin: 0px -17.14% -35px;
      height: inherit !important;
      display: block;
      max-height: inherit;
      @include desktop{
        display: block;
      }
      @include tablet{
        display: block;
        margin: 0px -7.14% -35px;
      }
      @include mobile{
        display: block;
        margin: 0px -5.5% -35px;
      }
    }
    .case-study-link{
      margin-top: 0px;
    }
  }
  #client-experience{
    background-color:#F7F7F7;
    padding: 10vh 0px;
    @include mobile{
      padding: 5vh 0px;
    }
    h2{
      color:$color-white;
      text-align: center;
      margin-bottom: 50px;
      @include mobile{
        margin-bottom: 25px;
      }
    }
    .experience-content{
      width:50%;
      display: inline-block;
      vertical-align: top;
      @include tablet{
        width: 100%;
      }
      @include mobile{
        width: 100%;
      }
      p{
        text-align: left;
        color: $color-black;
        font-size:32px;
        line-height: 45px;
        @include tablet{
          font-size:22px;
          line-height: 32px;
        }
        @include mobile{
          font-size:22px;
          line-height: 32px;
        }
        &:before{
          content:'';
          background: $background-gradient;
          height: 4px;
          width: 25px;
          display: block;
          margin-top:-40px;
          position: relative;
          margin-bottom: 20px;
        }
      }
    }
    .client-list{
      margin: 0px auto;
      display: inline-block;
      position: relative;
      width:35%;
      margin-left: 15%;
      padding: 2% 5% 2%;
      box-sizing: border-box;
      border-radius: 10px;
      background-color: $color-white;
      box-shadow: 5px 0px 25px rgba(0,0,0,.08);
      @include tablet{
        width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;
        margin-top: 10px;
      }
      @include mobile{
        width: 100%;
        margin-left: 0px;
        margin-bottom: 0px;
        margin-top: 10px;
      }
      a{
        color:$color-black;
        font-size: 16px;
        text-decoration: none;
        line-height: 20px;
        margin-bottom: 0px;
        position: relative;
        padding-bottom: 5px;
        display: block;
        width: auto;
        height: auto;
        vertical-align: top;
        &:hover{
          span{
            &:after{
              animation:lineSlide .5s ease forwards;
            }
          }
        }
        @include mobile{
          font-size: 15px;
        }
      }
      @keyframes lineSlide{
        0%{transform: translateX(0%);}
        49%{transform: translateX(-100%);}
        50%{transform: translateX(100%);}
        100%{transform: translateX(0%);}
      }
      span{
        width: auto;
        display: inline-block;
        overflow: hidden;
        transition:  all ease .25s;
        &:after{
          content:'';
          height: 2px;
          width: 100%;

          position: relative;
          display:  inline-block;
          vertical-align: top;
          margin-top: 5px;
          transition:  all ease .25s;
        }
      }
    }
  }
  .hidden{
    .experience-content, .client-list, .intro-copy, .caps-list{
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .fade-in-element{
    .experience-content, .client-list, .intro-copy, .caps-list{
      transition:  all ease .75s .25s;
      opacity: 1;
      transform: translateY(0px);
    }
    .caps-list{
      transition-delay: .5s;
    }
  }
}
