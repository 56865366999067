$orange: #d43500;
$creme: #f3eee6;
$gold: #e5d0a2;
$lime: #d9e024;
:root {
  --active-button: #4b4847;
  --inactive-button: #2d2b2a;
  --gardient: linear-gradient(25deg, #3fba7b, #00afef);
  --base-font: 'Montserrat';
  // --base-font: "georgia";
}

@mixin mobile {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin bpDown($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}

@mixin bpUp($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}
