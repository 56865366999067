//colors
$color-grey: #4a4d4c;
$color-darkgrey: #3a3a3c;
$color-lightgrey: #f7f7f7;
$color-red: #eb1946;
$color-green: #3fba7b;
$color-green-darker: #39b986;
$color-blue: #00afef;
$color-black: #181819;
$color-white: #ffffff;
$background-gradient: linear-gradient(25deg, #3fba7b, #00afef);

@mixin gradient-color {
  background: linear-gradient(25deg, #3fba7b, #00afef);
  background-clip: border-box;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

//media query break points
$mobile-width: 550px;
$tablet-width: 768px;
$max-width: 1480px;

@mixin max-width {
  @media (min-width: $max-width) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $tablet-width) and (max-width: $max-width) {
    @content;
  }
}
@mixin small-desktop {
  @media (min-width: $tablet-width) and (max-width: 1200px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: $mobile-width) and (max-width: $tablet-width) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

//fonts
@mixin font-light {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
}
@mixin font-book {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
}
@mixin font-medium {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
}
@mixin font-bold {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
}

//nod fonts
//Gesta Font family
@font-face {
  font-family: gesta;
  src: url('../public/nod-fonts/Gesta/RuiAbreu-Gesta-Medium.otf');
  font-weight: normal;
}
@mixin gesta {
  font-family: gesta, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin gesta-Bold {
  font-family: gesta, sans-serif;
  font-weight: 800;
  font-style: normal;
}

//Adieu Font family
@font-face {
  font-family: AdieuLight;
  src: url('../public/nod-fonts/GTF-Adieu/Adieu-Light.eot');
  src:
    url('../public/nod-fonts/GTF-Adieu/Adieu-Light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/nod-fonts/GTF-Adieu/Adieu-Light.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/nod-fonts/GTF-Adieu/Adieu-Light.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: normal;
}
@font-face {
  font-family: AdieuBlack;
  src: url('../public/nod-fonts/GTF-Adieu/Adieu-Black.eot');
  src:
    url('../public/nod-fonts/GTF-Adieu/Adieu-Black.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../public/nod-fonts/GTF-Adieu/Adieu-Black.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../public/nod-fonts/GTF-Adieu/Adieu-Black.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: normal;
}
@mixin Adieu-Light {
  font-family: AdieuLight, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05rem;
}
@mixin Adieu-Black {
  font-family: AdieuBlack, serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05rem;
}

//Clearface Font family
@font-face {
  font-family: Clearface;
  src: url('../public/nod-fonts/ITC-Clearface/ITC-ClearfaceStd-Regular.otf');
  font-weight: normal;
}
@mixin Clearface {
  font-family: Clearface, serif;
  font-weight: 400;
  font-style: normal;
}

//Add Red Arrow
@mixin red-arrow {
  &:after {
    content: '';
    background-image: url('../public/img/arrow-red.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 10px;
    margin-left: 0px;
    z-index: 1;
    transition: all ease 0.25s;
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translate(0, -50%);
  }
}

//Turquoise Tiny Line
@mixin turq-line {
  &:after {
    content: '';
    background: linear-gradient(25deg, #3fba7b, #00afef);
    width: 20px;
    height: 4px;
    z-index: 1;
    transition: all ease 0.25s;
    position: absolute;
    top: -30px;
    left: 0;
  }
}
