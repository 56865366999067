#tips,
#tip {
    header {
        width: unset;
        max-width: unset;
        margin: unset;
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        z-index: unset;
    }
    h1,
    h2 {
        margin: unset;
    }
    p {
        color: unset;
    }
}