@import '../../variables.scss';
#four{
  .page-wrap{
    padding-top: 150px;
    position: relative;
    background-color: #f7f7f7;
    @include mobile{
      padding-top: 75px;
    }
    &:after{
      content:'';
      width:50%;
      position: absolute;
      left: 50%;
      height: 100%;
      z-index: 0;
      top:0px;
      background: $background-gradient;
      @media all and (max-width:1180px){
        display: none;
      }
    }
    .button{
      padding: 18px;
      color:$color-black;
      font-size: 15px;
      width: 250px;
      text-align: center;
      cursor: pointer;
      margin-top: 10%;
      @media all and (max-width:800px){
        width: 90%;
      }
    }
  }
  .fourohfour{
    width: 50%;
    display: inline-block;
    padding-left: 10%;
    box-sizing: border-box;
    vertical-align: top;
    z-index: 1;
    position: relative;
    @media all and (max-width:1180px){
      width:120%;
      background-color: $color-black;
      margin-left: -10%;
      padding-left: 20%;
      padding-top: 100px;
    }
    @include tablet{
      padding-top: 50px;
    }
    @include mobile{
      padding-top: 50px;
    }
    h1{
      color: $color-white;
      font-size: 200px;
      @include font-bold;
      padding: 0px 0px 100px;
      margin: 0px;
      line-height: 200px;
      @media all and (max-width:1180px){
        font-size: 24vw;
        line-height: 24vw;
      }
      span{
        font-size: 200px;
        text-transform: none;
        line-height: 200px;
        @media all and (max-width:1180px){
          font-size: 24vw;
          line-height: 24vw;
        }
      }
    }
  }
  .text-wrap{
    width:50%;
    display: inline-block;
    padding: 0% 8% 20%;
    box-sizing: border-box;
    vertical-align: top;
    @media all and (max-width:1180px){
      width:100%;
      padding: 0px 0px 20%;
    }
    h4{

    }
  }
}
