@import './variables.scss';
#tips,
#tip {
    font-family: var(--base-font);
    position: relative;
    overflow: hidden;
    h2 {
        font-family: var(--playfair-font);
        font-size: 48px;
        font-weight: 400;
        line-height: 66px;
        letter-spacing: 0.02em;
        text-align: center;
        @include tablet {
            font-size: 28px;
            line-height: 40px;
        }
    }
    h1 {
        font-family: var(--bold-font);
        font-size: 56px;
        font-weight: 700;
        line-height: 70px;
        letter-spacing: 0em;
        margin-bottom: 6px;
        text-align: center;
        @include tablet {
            font-size: 50px;
            line-height: 60px;
        }
        @include mobile {
            font-size: 34px;
            line-height: 43px;
        }
    }
    .flex {
        display: flex;
    }
    .relative {
        position: relative;
    }
    .stars-wrap {
        justify-content: center;
        margin-bottom: 8px;
    }
    .button {
        padding: 12px 24px;
        font-family: var(--bold-font);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        min-width: 200px;
        letter-spacing: 0.04em;
        text-align: center;
        text-transform: uppercase;
        color: $creme;
        background: var(--inactive-button);
        border: 1px solid transparent;
        transition: all 0.2s ease;
        box-shadow: 0px 5px 20px 0px #33333340;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
            background-color: var(--active-button);
            color: var(--inactive-button);
        }
        &.active {
            background-color: var(--active-button);
            color: var(--inactive-button);
        }
        @include mobile {
            flex-basis: 100%;
        }
        &.view-more {
            border-radius: 4px;
        }
    }
    .cta-wrap {
        display: inline-block;
    }
    .cta {
        background-color: transparent;
        transition: all 0.3s ease;
        position: relative;
        text-decoration: none;
        margin-bottom: 0;
        white-space: nowrap;
        padding: 5px 20px;
        border-radius: 100px;
        color: $creme;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &.arrow,
        .has-icon {
            display: flex;
            align-items: center;
        }
        &.back {
            img.arrow-icon {
                transform: rotate(180deg) translateX(12px);
            }
            &:hover {
                text-decoration: none;
                img.arrow-icon {
                    transform: translateX(-14px) rotate(180deg);
                }
            }
        }
        &.wide {
            padding: 0px 30px;
        }
        .icon {
            position: absolute;
        }
        img.arrow-icon {
            transition: all 0.2s ease;
            margin-left: 7px;
        }
        &:hover {
            text-decoration: none;
            img.arrow-icon {
                transform: translateX(4px);
            }
        }
        &.orange-outline {
            border: 4px solid $orange;
            &:hover {
                background-color: $orange;
                color: $white;
            }
        }
        &.black-outline {
            border: 4px solid $black;
            color: $black;
            img.arrow-icon.white,
            img.white {
                opacity: 0;
            }
            &:hover {
                color: $white;
                background-color: $black;
                img.arrow-icon.white,
                img.white {
                    opacity: 1;
                }
                img.arrow-icon.black,
                img.black {
                    opacity: 0;
                }
            }
        }
        &.orange-outline {
            border: 4px solid $orange;
        }
        &.white-outline {
            border: 4px solid $creme;
            img.arrow-icon.black,
            img.black {
                opacity: 0;
            }
            &:hover {
                background-color: $creme;
                color: $black;
                img.arrow-icon.white,
                img.white {
                    opacity: 0;
                }
                img.arrow-icon.black,
                img.black {
                    opacity: 1;
                }
            }
        }
        &.standard {
            min-width: 0px;
        }
        .arrow,
        .icon-wrap {
            transform: translateY(-1px);
            margin-left: 5px;
            position: relative;
            height: 11px;
            width: 17px;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .icon-wrap {
            height: 17px;
        }
    }
    .flourish-container {
        position: relative;
    }
    .flourish {
        position: absolute;
        height: 156px;
        width: 156px;
        background-image: url("../Tips/img/flourish.svg");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 2;
        pointer-events: none;
        opacity: 0.3;
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.top.right {
            transform: rotate(90deg);
        }
        &.bottom.left {
            transform: rotate(270deg);
        }
        &.bottom.right {
            transform: rotate(180deg);
        }
        @include tablet {
            height: 80px;
            width: 80px;
        }
        @include mobile {
            height: 56px;
            width: 56px;
        }
    }
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    }
    @media (min-width: 768px) {
        .container {
            width: 750px;
        }
    }
    @media (min-width: 992px) {
        .container {
            width: 970px;
        }
    }
    @media (min-width: 1200px) {
        .container {
            width: 97%;
            max-width: 97%;
        }
    }
    header {
        .navigation {
            display: flex;
            justify-content: space-between;
            padding-top: 0px;
            margin-top: 58px;
            margin-bottom: 81px;
            @include mobile {
                transform: translateY(0px);
                flex-direction: column;
                justify-content: center;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .left {
                display: flex;
                gap: 20px;
                @include mobile {
                    flex-direction: column;
                    justify-content: center;
                }
                .cta {
                    display: inline-block;
                }
                .cta-wrap {
                    display: flex;
                    justify-content: center;
                }
            }
            .back {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                border-radius: 4px;
                &.white-bkg {
                    background-color: $creme;
                }
                &.black-bkg {
                    background-color: $black;
                }
            }
            .share-wrap {
                position: relative;
                @include mobile {
                    margin-top: 20px;
                    margin-bottom: 0px;
                    display: flex;
                    justify-content: center;
                }
                .share-link-wrap {
                    bottom: -65px;
                    z-index: 5;
                    @include mobile {
                        right: 0;
                        transform: translateX(0);
                    }
                }
            }
        }
        .subhead p {
            text-align: center;
            font-size: 18px;
            line-height: 26px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0em;
            max-width: 660px;
            margin: 20px auto 0;
            @include mobile {
                font-size: 16px;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
                line-height: 22px;
                padding-bottom: 0px;
            }
        }
        h1 {
            margin-bottom: 6px;
            font-family: var(--bold-font);
            text-align: center;
            margin-top: 0px;
            @include mobile {
                margin-top: 30px;
                margin-bottom: 10px;
            }
        }
        h2 {
            text-align: center;
        }
    }
    .filter-wrap {
        display: flex;
        justify-content: space-around;
        border-bottom: 2px solid rgba(241, 238, 232, 0.5);
        margin-bottom: 70px;
        @include tablet {
            margin-bottom: 30px;
            flex-direction: column;
            border-bottom: 0px solid transparent;
            align-items: center;
        }
        .filter {
            font-family: var(--bold-font);
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            cursor: pointer;
            padding: 8px;
            opacity: 0.5;
            position: relative;
            color: $white;
            transition: all 0.2s ease;
            border: 1px solid transparent;
            background-color: transparent;
            margin-bottom: 8px;
            @include tablet {
                margin-left: 0;
                margin-right: 0;
            }
            &:hover {
                opacity: 1;
                text-decoration: none;
            }
            &:focus {
                outline: none;
            }
            &:focus-visible {
                outline: 2px solid $white;
            }
            &.active {
                opacity: 1;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -9px;
                    height: 6.55px;
                    width: 192px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $white;
                    border-radius: 30px 30px 0px 0px;
                    outline-color: transparent;
                    &:focus {
                        outline-color: transparent;
                        outline: none;
                    }
                }
            }
        }
    }
    .card {
        box-shadow: 0px 5px 20px 0px #33333340;
        border: 0px solid transparent;
        border-radius: 30px;
        overflow: hidden;
        &.hidden {
            display: none;
        }
        &:hover {
            text-decoration: none;
        }
        .card-top {
            position: relative;
            height: 221px;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
                display: none;
            }
            .logo {
                display: flex;
                justify-content: center;
                img {
                    min-height: 100px;
                    max-width: 295px;
                }
            }
            &.image {
                &::before {
                    display: none;
                }
            }
            .image {
                width: 100%;
                img {
                    object-fit: cover;
                    max-height: 221px;
                    min-height: 221px;
                    width: 100%;
                    object-position: center;
                }
            }
            a {
                width: 100%;
            }
            .card-divider {
                position: absolute;
                bottom: -4px;
                left: -1px;
                width: 100%;
                object-fit: cover;
                svg {
                    width: calc(100% + 1px);
                }
                @include tablet {
                    bottom: -1px;
                }
            }
        }
        .card-body {
            text-align: center;
            background-color: $grey;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            padding: 1.25rem;
            h3,
            h4,
            h5,
            h6,
            .card-title {
                max-width: 86%;
                margin-left: auto;
                margin-right: auto;
                font-size: 20px;
                font-weight: 600;
                font-family: var(--base-font);
                color: $black;
                min-height: 60px;
                @include tablet {
                    min-height: 0;
                    max-width: 100%;
                }
            }
            .cta-wrap {
                margin-top: auto;
                @include tablet {
                    margin-top: 0;
                }
                flex-grow: 0;
            }
        }
    }
    .footer-link {
        &:hover {
            text-decoration: none;
            .link {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    footer {
        text-align: center;
        padding-top: 31px;
        padding-bottom: 31px;
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .link {
                display: flex;
                justify-content: center;
                position: relative;
                align-items: center;
                min-height: 22px;
                gap: 10px;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    height: 1px;
                    background-color: $black;
                    opacity: 0;
                }
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
                line-height: 0;
                font-size: 18px;
                font-weight: 500;
                @include mobile {
                    text-align: center;
                }
            }
        }
    }
    .share-wrap {
        position: relative;
        z-index: 5;
        .share-link-wrap {
            position: absolute;
            bottom: -70px;
            width: calc(100%);
            right: 0;
            background-color: var(--inactive-button);
            opacity: 0;
            visibility: hidden;
            flex-wrap: wrap;
            transition: opacity 0.2s ease;
            border-radius: 4px;
            padding: 12px;
            justify-content: center;
            &.active {
                display: flex;
                opacity: 1;
                visibility: visible;
            }
            a {
                color: white;
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                &.facebook,
                &.mail {
                    background-color: var(--active-button);
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                &.mail {
                    img {
                        width: 20px;
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    main {
        .container {
            position: relative;
        }
    }
}