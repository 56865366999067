@import '../../variables.scss';

#news{
  padding-top: 150px;
  @include mobile{
    padding-top: 100px;
  }
  h1{
    @include font-bold;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .post-card{
    vertical-align: top;
    margin-bottom:40px;
    &.hide{
      display:none;
    }
    .image-wrap{
      max-height: 200px;
      overflow: hidden;
      img{
        display: block;
      }
    }
    .hide{
      display: none;
    }
    .content{
      padding: 10%;
      background-color: $color-white;
      h3{
        color: $color-black;
        margin-top: 0px;
      }
      p{
        height: 100px;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .post-nav{
    background-color: #F7F7F7;
    padding-bottom: 100px;

    .content-wrap{
      max-width: 1300px;
      font-size: 15px;
      position: relative;
      padding: 0px;
      a{
        color:$color-blue;
        opacity: .45;
        z-index: 5;
        position: relative;
        padding: 15px;
        @include font-bold;
        transition: all ease .25s;
      }
    }
    .prev{
      float: left;
      padding: 0px;
      color: $color-black !important;
      opacity: 1 !important;
      &.\31{
        color: $color-darkgrey;
        pointer-events: none;
      }
    }
    .next{
      float: right;
      padding: 0px;
      color: $color-black !important;
      opacity: 1 !important;
      &.last{
        color: $color-darkgrey;
        pointer-events: none;
      }
    }
    br{
      clear: both;
    }
    .count{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      padding: 13px 15px 0px;
      @include mobile{
        display: none;
      }
      a{
        cursor: pointer;
      }
      a.\31:first-of-type,
      a.\32:nth-of-type(2),
      a.\33:nth-of-type(3),
      a.\34:nth-of-type(4),
      a.\35:nth-of-type(5),
      a.\36:nth-of-type(6),
      a.\37:nth-of-type(7),
      a.\38:nth-of-type(8){
        color: $color-darkgrey;
        pointer-events: none;
        opacity: 1;
      }
    }
  }
  .all-posts{
    background-color: #F7F7F7;
    min-height: 700px;
    padding-top: 5vh;
    h2{
      font-size: 35px;
      padding: 0px 8%;
      @include mobile{
        font-size: 25px;
      }
    }
    .content-wrap{
      transition: all ease .5s;
      display: grid;
      grid-template-columns: repeat(3, 31%);
      grid-gap: 3.5%;
      transform: translateY(-10px);
      padding-bottom: 40px;
      @include tablet{
        grid-template-columns: repeat(2, 49%);
      }
      @include mobile{
        grid-template-columns: repeat(1, 100%);
        grid-gap: 0%;
      }
      &.visible{
        opacity: 1;
        transform: translateY(0);
      }
      &.hidden{
        opacity: 0;
        transform: translateY(-10px);
      }
    }
  }
  .openings{
    background: $color-black;
    padding: 15vh 0px;
    h2{
      color:$color-white;

      font-size: 46px;
      margin-bottom: 80px;
      @media all and (max-width:1000px){
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 40px;
      }
    }
    p{
      color: $color-white;
      font-size: 26px;
      @media all and (max-width:1000px){
        font-size: 20px;
        line-height: 24px;
      }
      &:after{
        content:"\00d7";

        font-size: 30px;
        display: block;
        margin-top: 70px;
        margin-bottom: 40px;
      }
    }
    .text-wrap{
      width:55%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8%;
      @media all and (max-width:1000px){
        width: 100%;
      }
    }
    .button{
      display: inline-block;
      width: 30%;
      vertical-align: middle;
      text-align: center;

      @media all and (max-width:1000px){
        width:50%;
      }
      @include mobile{
        width: 100%;
        box-sizing: border-box;
      }
      &:hover{

        color:$color-black;

        &:after{
          background-image: url(../../../public/img/arrow-black.svg);
        }
      }
    }
  }
  .filter-wrap{
    display: block;
    margin: 20px auto 5vh;
    text-align: center;
    .filter-innerwrap{
      width: auto;
      position: relative;
      display: inline;
      span.line{
        position: absolute;
        left: 0px;
        bottom: -5px;
        height: 10px;
        background-color: $color-red;
        opacity: .5;
        width: 10px !important;
        opacity: 1;
        transition: all ease .5s;
        top:3px;
        @include mobile{
          display: none;
        }
      }
    }
    .filterLink{
      @include font-bold;
      display: inline-block;
      margin-right: 55px;
      text-align: center;
      font-size: 15px;
      color: $color-black;
      position: relative;
      cursor: pointer;
      @include mobile{
        display: block;
        width: 85%;
        margin: 10px 8%;
        text-align: left;
      }
      @include mobile{
        &.active{
          &:after{
            content:'';
            display: block;
            background-color: $color-blue;
            height: 2px;
            width: 60px;
            position: absolute;
            bottom:-5px;
          }
        }
        &.active:nth-of-type(2){
          &:after{
            width: 130px;
          }
        }
        &.active:nth-of-type(3){
          &:after{
            width: 194px;
          }
        }
      }
      span{
        width:12px;
        height: 12px;
        display: inline-block;
        margin-right: 10px;
        background-color: red;
      }
      &:first-of-type{
        span{
          display: none;
        }
      }
      &:nth-of-type(2){
        span{
          background-color: $color-blue;
        }
      }
      &:nth-of-type(3){
        span{

        }
      }
      &:last-of-type{
        margin-right: 0px;
      }
    }
  }
}
