@import '../../variables.scss';
#StaticPage{
  position: relative;
  display: block;
  width: 100%;
  background-color: $color-white;
  padding: 5vh 0px;
  .lockup{
    text-align: center;
    padding: 10vh 0px 0px;
    clear: both;
    display: flex;
    justify-content: center;
    width:90%;
    max-width: 1300px;
    margin: 0 auto 60px;
    align-items: center;
    @include tablet{
      display: block;
      margin-bottom: 60px;
    }
    @include mobile{
      display: block;
      margin-bottom: 60px;
    }
    &:nth-of-type(odd){
      flex-direction: row-reverse;
    }
    .image,
    .content{
      display: inline-block;
      vertical-align: middle;
      transition: all ease .75s .15s;
      vertical-align: middle;
      @include tablet{
        width:100%;
      }
      @include mobile{
        width:100%;
      }
      &.hidden{
        opacity: 0;
        transform: translateY(20px);
      }
      &.fade-in-element{
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .image{
      width: 40%;
      margin: 0px;
      text-align: center;
      background-image: url(../../../public/img/imageBg-texture.png);
      background-size: 85%;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 800px;
      img{
        width: 100%;
      }
      @include tablet{
        width:100%;
        margin: 0 auto;
        float: none;
      }
      @include mobile{
        width:100%;
        margin: 0 auto;
        float: none;
      }
    }
    .content{
      margin: 0px 5% 0px 0px;
      width:60%;
      text-align: left;
      @include tablet{
        width:100%;
        float: none;
      }
      @include mobile{
        width:100%;
        margin: 20px auto;
        float: none;
      }
      .type{
          h2{
            color: $color-black;
            @include font-bold;
            font-size: 50px;
            margin-bottom: 5px;
            margin-top: 0px;
            @include mobile{
              font-size: 35px;
              margin-bottom: 10px;
            }
            &:after{
              content:'';
              margin-left: 10px;
              margin-top: 11px;
              width: 8px;
              height: 8px;
              background-color: $color-red;
              display: inline-block;
              vertical-align: middle;
            }
          }
          p{
            margin-top: 5px;
            color: $color-black;
            margin-bottom: 40px;
          }
      }
      .button{
      }
    }
    br{
      clear: both;
      display: block;
    }
  }
}
