@import '../../variables.scss';

.slick-list,.slick-slider,.slick-track{
    position:relative;
    display:block
}
.slick-loading .slick-slide,.slick-loading .slick-track{
    visibility:hidden
}
.slick-slider{
    box-sizing:border-box;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-touch-callout:none;
    -khtml-user-select:none;
    -ms-touch-action:pan-y;
    touch-action:pan-y;
    -webkit-tap-highlight-color:transparent;
    position: relative;
    .slick-arrow{
      border: none;
      color:transparent;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      background-color: $color-white;
      position: absolute;
      top:50%;
      transform:translateY(-50%) rotate(-180deg);
      
      left: 10px;
      z-index: 1;
      cursor: pointer;
      &:before{
        content:"";
        width: 20px;
        height: 10px;
        background-image: url(../../../public/img/arrow-black.svg);
        background-size: contain;
        position: absolute;
        display: block;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        top: 50%;
      }
      &.slick-next{
          right: 10px;
          left: inherit;
          transform:translateY(-50%) rotate(0deg);
      }
    }
}
.slick-list{
    overflow:hidden;
    margin:0;
    padding:0;
    transition: height ease .5s;
}
.slick-list:focus{
    outline:0
}
.slick-list.dragging{
    cursor:pointer;
    cursor:hand
}
.slick-slider .slick-list,.slick-slider .slick-track{
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0);
    -ms-transform:translate3d(0,0,0);
    -o-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
}
.slick-track{
    top:0;
    left:0
}
.slick-track:after,.slick-track:before{
    display:table;
    content:''
}
.slick-track:after{
    clear:both
}
.slick-slide{
    display:none;
    float:left;
    height:100%;
    min-height:1px
}
[dir=rtl] .slick-slide{
    float:right
}
.slick-slide img{
    display:block;
    min-width:100%;
}
.slick-slide.slick-loading img{
    display:none
}
.slick-slide.dragging img{
    pointer-events:none
}
.slick-initialized .slick-slide{
    display:block
}
.slick-vertical .slick-slide{
    display:block;
    height:auto;
    border:1px solid transparent
}
.slick-arrow.slick-hidden{
    display:none
}
.slick-dots{
  z-index: 1;
  display: block;
  background-color:$color-white;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: 50%;
  bottom: 20px;
  li, ul{
    list-style: none;
    padding-left: 0px;
    text-indent: 0px;
    position: relative;
    color:$color-black;
    
    font-size: 14px;
    &:before{
      display: none;
    }
  }
  li{
    display: none;
    &.slick-active{
      display: block;
    }
  }
  .item{
    position: absolute;
    left: 50%;
    top: 50%;
    height: 15px;
    transform: translate(-50%, -50%);
    span{
      padding: 0px 2px;
    }
  }
}
/*# sourceMappingURL=slick.min.css.map */
