@import '../../variables.scss';

#gratitude {
  padding-top: 150px;
  @include mobile {
    padding-top: 100px;
  }
  .intro {
    h1 {
      @include font-bold;
      color: $color-white;
      margin-bottom: 80px;
      margin-top: 0px;
      &:after {
        content: '';
        margin-left: 10px;
        margin-top: 5px;
        width: 8px;
        height: 8px;
        background-color: #eb1946;
        display: inline-block;
        vertical-align: middle;
      }
    }
    h3 {
      color: $color-white;
      @include font-book;
      margin: 100px 0px 150px;
      &:before {
        content: '';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top: -40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
  }
  .general {
    text-align: center;
    position: relative;
    // background-image: url(../../../public/img/texture-horiz.jpg);
    background-size: cover;
    background-color: $color-white;
    .content-wrap {
      padding: 0vh 0px 15vh;
      max-width: 100%;
      width: 100%;
      margin: 0px;
      @include mobile {
        padding: 5vh 0px 10vh;
      }
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
      color: $color-black;
      margin-bottom: 25px;

      @include mobile {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
    .content-wrap {
      max-width: 1300px;
      margin: 0 auto;
      padding-bottom: 91px;

      .container {
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
        @media (min-width: 768px) {
          width: 750px;
        }
        @media (min-width: 992px) {
          width: 970px;
        }
        @media (min-width: 1200px) {
          width: 1000px;
        }
      }

      .generator {
        transition: transform 0.7s ease;
        position: relative;
        &.active {
          transform: translateY(-67px);

          @include mobile {
            transform: translateY(-107px);
          }
        }
        .cover {
          position: absolute;
          height: 100%;
          width: 100%;
          border-radius: 4px;
          top: 0;
          right: 0;
          background-color: white;
          z-index: 3;
          transition: all 0.4s;

          &.collapsed {
            width: 0;
          }
          &.expanded {
            width: 100%;
          }
          .border {
            position: absolute;
            height: 100%;
            width: 0px;
            background: $background-gradient;
            top: 0;
            left: 0;
            transition: all 0.5s;

            &.active {
              width: 1px;
            }
          }
          .flip {
            transform: rotate(180deg);
          }
        }

        .bkg.init {
          min-height: 100px;

          @include mobile {
            min-height: 111px;
          }

          .inner {
            height: 0;
          }
        }

        .bkg {
          position: relative;
          min-height: 353px;
          border-radius: 4px;
          background-color: white;
          max-width: 100%;
          box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.07);
          padding: 34px 70px;
          transition: height 0.6s ease-in;

          @include mobile {
            padding: 18px 23px 32px;
            min-height: 200px;
          }

          .initial-state {
            transition: opacity 0.2s ease;
            &.active {
              opacity: 1;
            }
            opacity: 0;
            h2 {
              font-size: 36px;
              font-weight: 700;
              line-height: 46px;
              color: #bdbdbd;
              max-width: 411px;
              margin-top: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              @include mobile {
                font-size: 24px;
                line-height: 27px;
                width: 65%;
              }
              @include tablet {
                font-size: 32px;
                line-height: 40px;
              }
            }
            .arrow {
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(285%, -4%);
              transition: all 0.2s ease;
              background-image: url('../../../public/img/arrow.svg');
              background-size: contain;
              background-repeat: no-repeat;
              height: 135px;
              width: 72px;

              @include mobile {
                transform: scale(0.7) translate(133%, 3%);
              }

              &.active {
                opacity: 1;
              }
            }
          }

          .inner {
            display: flex;
            column-gap: 50px;
            opacity: 0; //hide initially
            position: relative;
            transition: all 2s ease;

            &.active {
              opacity: 1;
            }

            @include mobile {
              flex-wrap: wrap;
            }
            @include tablet {
              flex-wrap: wrap;
            }

            .box {
              position: relative;
              transition: all 0.4s ease-in;
            }

            .left {
              flex-basis: 47%;
              @include mobile {
                flex-basis: 100%;
              }
              @include tablet {
                flex-basis: 100%;
              }

              img.main-image {
                max-width: 100%;
                position: relative;
                z-index: 2;
                transition: all 0.4s ease-out;
                opacity: 0;

                @include mobile {
                  max-width: 65%;
                }
                @include tablet {
                  max-width: 300px;
                }
              }

              .scale {
                transform: scale(1.02);
                opacity: 1 !important;
              }
            }
            .right {
              flex-basis: 60%;
              @include mobile {
                flex-basis: 100%;
              }
              @include tablet {
                flex-basis: 100%;
              }
              .idea-number {
                font-family: 'Montserrat', sans-serif;
                font-weight: normal;
                font-size: 20px;
                line-height: 25px;
                padding-top: 20px;
                margin-bottom: 23px;
                @include mobile {
                  padding-top: 20px;
                }
                @include tablet {
                  padding-top: 20px;
                }
              }
              h2.message {
                margin-bottom: 30px;
                line-height: 42px;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                color: #181819;

                @include mobile {
                  font-size: 20px;
                  line-height: 29px;
                  margin-bottom: 0;
                }
                @include tablet {
                  font-size: 24px;
                  line-height: 32px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      section.more-button {
        margin-top: 0px;
        margin-bottom: 107px;
        text-align: center;

        @include mobile {
          margin-top: -51px;
          margin-bottom: 31px;
        }
        .container {
          button {
            cursor: pointer;
            border: 1px solid transparent;
            font-size: 26px;
            line-height: 33px;
            padding: 15px 5%;
            text-transform: capitalize;
            margin: 0 auto;

            &:focus {
              outline: 0px;
            }

            @include mobile {
              padding: 15px 10%;
            }
          }
        }
      }

      section.share-links {
        @include mobile {
          margin-bottom: 28px;
        }
        .container {
          text-align: center;

          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            color: #828282;
            margin-bottom: 33px;
          }

          .social-wrap {
            display: flex;
            justify-content: center;
            a {
              margin-right: 7px;
              img {
                width: 39px;
                height: 39px;
              }
            }
          }
        }
      }

      section.note {
        // max-width: 671px;
        text-align: center;
        // margin: 0 auto;
        // width: 80%;

        p {
          padding-top: 52px;
          font-size: 20px;
          margin: 0 auto;
          width: 80%;

          @include mobile {
            width: 100%;
            font-size: 18px;
            line-height: 28px;
            padding-top: 24px;
          }
          a {
            color: black;
            text-decoration: underline;
          }
        }
      }
      section.hidden-preload-images {
        display: none;
      }

      .makeshift-mail-icon {
        height: 39px;
        width: 39px;
        position: relative;
        background: linear-gradient(25deg, #3fba7b, #00afef);
        // border: 1px solid black;
        border-radius: 4px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          height: 27px;
          width: 27px;
          transform: translate(-51%, -50%);
          background-image: url('../../../public/img/social/mail.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .hidden {
    .caps-slide,
    .content-wrap {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .fade-in-element {
    .caps-slide,
    .content-wrap {
      opacity: 1;
      transform: translateY(0px);
      transition: all ease 0.75s 0s;
    }
  }
}
