@import '../../variables.scss';

#contact{
  padding-top: 150px;
  @include mobile{
    padding-top: 100px;
  }
  .intro{
    h1{
      @include font-bold;
      color: $color-white;
      margin-bottom: 80px;
      margin-top: 0px;
      &:after{
        content: '';
        margin-left: 10px;
        margin-top: 5px;
        width: 8px;
        height: 8px;
        background-color: #EB1946;
        display: inline-block;
        vertical-align: middle;
      }
    }
    h3{
      color: $color-white;
      @include font-book;
      margin: 100px 0px;
      &:before{
        content:'';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top:-40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
  }
  .general{
    text-align: center;
    position: relative;
    // background-image: url(../../../public/img/texture-horiz.jpg);
    background-size: cover;
    background-color: $color-white;
    .content-wrap{
      padding: 10vh 0px 15vh;
      max-width: 100%;
      width: 100%;
      margin: 0px;
      @include mobile{
        padding: 5vh 0px 10vh;
      }
    }
    h3{
      text-transform: uppercase;
      font-size: 28px;
      line-height: 32px;
      color: $color-black;

      margin-bottom: 25px;
      @include mobile{
        font-size: 22px;
        margin-bottom: 15px;
      }
    }
    .content-wrap{
      max-width: 1300px;
      margin: 0 auto;
    }
    .contact-info{
      text-align: left;
      display: inline-block;
      padding: 0px 10% 0px 0px;
      width:33%;
      vertical-align: top;
      box-sizing: border-box;
      @include desktop{
        display: block;
        margin: 40px auto;
        width:90%;
      }
      @include tablet{
        display: block;
        margin: 40px auto;
        width:90%;
      }
      @include mobile{
        display: block;
        margin: 40px auto;
        width:90%;
      }
      a{
        color: $color-black;
        text-decoration: none;
        @include font-bold;
        font-size: 24px;
        line-height: 33px;
        position: relative;
        &:after{
          content:"";
          background-image: url(../../../public/img/arrow-red.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 30px;
          height: 15px;
          display: block;
          margin-left: 0px;
          z-index: 1;
          position: relative;
          transition: all ease .25s;
          position: relative;
          top: 15px;
          transition: all ease .25s;
          @include mobile{
            margin-top: 6px;
          }
        }
        &:hover{
          &:after{
            transform: translateX(10px);
          }
        }
        @include mobile{
          font-size: 28px;
          line-height: 38px;
        }
      }
      h4{
        display: block;
        margin-right: 10px;
        text-transform: none;
        margin:5px 10px 5px 0px;
        @include font-book;
        font-weight: 500;
        font-size: 19px;
        line-height: 30px;
      }
      @include mobile{
        display: block;
        &:nth-of-type(2n){
          border: none;
        }
      }
    }
  }
  .hidden{
      .caps-slide, .content-wrap{
        opacity: 0;
        transform: translateY(20px);
      }
  }
  .fade-in-element{
    .caps-slide, .content-wrap{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s 0s;
    }
  }
}
