@import '../../variables.scss';
#ideas{
  padding-top: 100px;
  @include tablet{
    width:90%;
    margin: 0 auto;
    padding-top: 60px;
  }
  @include mobile{
    width:90%;
    margin: 0 auto;
    padding-top: 40px;
  }
  .intro{
    margin: 0 auto;
    max-width: 1000px;
    text-align: left;
    width: 90%;
    h2{
      color: $color-white;
      @include font-bold;
      &:after{
        content:'';
        margin-left: 10px;
        margin-top: 11px;
        width: 8px;
        height: 8px;
        background-color: $color-red;
        display: inline-block;
        vertical-align: middle;
      }
    }
    p{
      font-size: 24px;
      color: $color-white;
      margin-bottom: 60px;
      @include font-book;
      @include mobile{
        font-size: 18px;
      }
      &:before{
        content:'';
        width:20px;
        background: $background-gradient;
        height: 3px;
        display: block;
        position: relative;
        border-radius: 5px;
        margin: 0px 0px 15px;
      }
    }
  }
  background-color: $color-black;
  .block-wrap{
    max-width: 1300px;
    margin: 0 auto 20px;
    width:90%;
    text-align: center;
    @include tablet{
      width:100%;
      margin: 0 auto;
    }
    @include mobile{
      width:100%;
      margin: 0 auto;
    }
  }
  .ideaBlock{
    background-color: $color-white;
    width:31.3%;
    margin-right: 3%;
    display: inline-block;
    vertical-align: top;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    @include tablet{
      display: block;
      width:100%;
      margin: 0 auto 40px;
    }
    @include mobile{
      display: block;
      width:100%;
      margin: 0 auto 40px;
    }
    &:last-of-type{
      margin-right: 0px;
    }
    img{
      width: 100%;
      display: block;
    }
    .cardContent{
      padding: 10%;
      h4{
        @include font-bold;
        font-size: 20px;
        text-transform: none;
        color: $color-black;
        margin-bottom: 10px;
        margin-top: 0px;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: $color-black;
      }
    }
  }
  .button{
    display: block;
    margin: 60px auto;
    max-width: 200px;
  }
  &.hidden{
    h2, .ideaBlock, .intro{
      opacity: 0;
      transform: translateY(20px);
      transition: all ease .5s;
    }
  }
  &.fade-in-element{
    h2, .intro{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .5s .25s;
    }
    .ideaBlock{
      opacity: 1;
      transform: translateY(0px);
      &:nth-of-type(1){
        transition: all ease .5s .35s;
      }
      &:nth-of-type(2){
        transition: all ease .5s .45s;
      }
      &:nth-of-type(3){
        transition: all ease .5s .55s;
      }
    }
  }
}
