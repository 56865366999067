@import '../Tips/variables.scss';
#tip {
    background-color: $creme;
    .flourish-wrap {
        position: relative;
    }
    .flourish {
        // background-image: url('../images/holiday/flourish-black.svg');
        background-image: url("../Tips/img/flourish-black.svg");
    }
    h2 {
        font-family: var(--bold-font);
        @include mobile {}
    }
    header {
        .container {
            .navigation {
                align-items: center;
                position: relative;
                z-index: 5;
                @include mobile {
                    justify-content: center;
                }
                justify-content: flex-end;
            }
            h1 {
                max-width: 556px;
                text-align: center;
                margin: 0 auto 75px;
                @include mobile {
                    margin-bottom: 35px;
                }
            }
        }
    }
    main {
        section.tip-breakdown {
            padding-bottom: 50px;
            .container {
                display: flex;
                flex-wrap: wrap;
                max-width: 1290px;
                gap: 75px;
                @include tablet {
                    gap: 35px;
                }
                @include mobile {
                    gap: 25px;
                }
                &.tip-content {
                    padding-bottom: 120px;
                    @include mobile {
                        padding-bottom: 40px;
                    }
                }
                h2 {
                    margin-top: 0;
                    font-family: var(--medium-font);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: bold;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 7px;
                    color: #000;
                }
                button {
                    box-shadow: none;
                }
                .left {
                    flex-basis: 50%;
                    max-width: 528px;
                    @include mobile {
                        flex-basis: 100%;
                        margin-bottom: 0px;
                        max-width: 100%;
                    }
                    img {
                        border-radius: 30px;
                        width: 100%;
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                        max-height: 328px;
                        object-fit: cover;
                        @include mobile {
                            object-fit: cover;
                        }
                    }
                }
                .right {
                    flex-basis: 50%;
                    @include mobile {
                        flex-basis: 100%;
                    }
                    iframe {
                        width: 100%;
                    }
                    p,
                    li,
                    em {
                        font-size: 18px;
                        margin-top: 0;
                        line-height: 28px;
                        color: black;
                    }
                    ul {
                        padding-left: 21px;
                    }
                    a:not(.cta) {
                        text-decoration: underline;
                    }
                    .share-wrap {
                        max-width: 200px;
                        @include mobile {
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .share-link-wrap {
                            bottom: -57px;
                            right: unset;
                            left: 0;
                        }
                    }
                    a:not(.cta) {
                        color: black;
                    }
                    button.share {
                        position: relative;
                        background-color: var(--active-button);
                    }
                }
            }
        }
    }
    section.more-tips {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
        @include mobile {
            padding-top: 30px;
        }
        .container {
            position: relative;
            .stars {
                @include mobile {
                    display: none;
                }
            }
            .title {
                h2 {
                    text-align: center;
                    color: $black;
                    font-size: 32px;
                    line-height: 42px;
                    margin-top: 0;
                    margin-bottom: 40px;
                    font-weight: 500;
                    @include mobile {
                        margin-bottom: 20px;
                    }
                }
            }
            .cards {
                justify-content: center;
                flex-wrap: wrap;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                padding-bottom: 40px;
                max-width: 1060px;
                margin-left: auto;
                margin-right: auto;
                @include mobile {
                    grid-template-columns: 1fr;
                }
            }
            .button-wrap {
                .container {
                    display: flex;
                    justify-content: center;
                    max-width: 100%;
                    .button {
                        max-width: 450px;
                        background-color: $black;
                        color: $white;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    footer {
        .container {
            color: $white;
        }
    }
}