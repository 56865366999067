@import '../../variables.scss';
#videoPlayer{
  .thumbnail{
    cursor: pointer;
    width:100%;
    opacity: 1;
    z-index: 10;
    position: absolute;
    top:0px;
    left: 0px;
    &.active{
      pointer-events: none;
      animation: fadeOutOpacity ease 1s forwards;
    }
  }
  @keyframes fadeOutOpacity{
    0%{opacity: 1;}
    100%{opacity: 0;}
  }
}
