@import 'variables.scss';
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  max-width: 100%;
  height: auto;
  background-color: $color-black;
}

.app{
  width: 100%;
  position: relative;
  min-height: 100vh;
  &.open{
    .nav-wraper{
      top:0px;
      &:after{
        top:-100px;
      }
    }
    .text-nav{
      top:-100px !important;
    }
  }
}
.loading-wrap{
  position: fixed;
  width: 100%;
  height: 100vh;
}
.loading{
  width:200px;
  height:300px;
  position: absolute;
  display: block;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;


  img{
    display: inline-block;
    margin-bottom: 10px;
    animation: rotateObj linear 4s infinite;
  }
}

//Page Transitions

.home{
  .PageTransition-enter.PageTransition-enter-active{
    &#home{
      &:before{
        animation:SlideOutHome 1s ease .025s forwards;
      }
      &:after{
        animation:SlideOutHome 1s ease forwards;
      }
    }
    section.active{
      opacity: 0 !important;
      transition-delay: 0s;
    }
  }
}
.app.active{
  .page-transitions{
    &:before{
      width:100vw;
      height: 100vh;

      position: fixed;
      left:0;
      top: 0px;
      z-index: 10;
      display: block;
      content: "";
      opacity: 0;
      animation:SlideOut 1.15s ease .025s forwards;
    }
    &:after{
      width:100vw;
      height: 100vh;

      position: fixed;
      left: 0%;
      top: 0px;
      z-index: 10;
      display: block;
      content: "";
      opacity: 0;
      animation:SlideOut 1.15s ease forwards;
    }
  }
  &.open, &.enter{
    .page-transitions{
      &:before{
        display: none !important;
        opacity: 0 !important;
        animation: none;
      }
      &:after{
        display: none !important;
        opacity: 0 !important;
        animation: none;
      }
    }
  }
  footer{
    transition: all ease .25s;
    opacity: 0;
  }
}


.page-transitions{
  //hide transition when menu is in use
  transition: all ease .5s;
  width: 100%;

  .PageTransition-appear {
    opacity: 0.01;
    transform: translateY(15px);
  }

  .PageTransition-appear.PageTransition-appear-active {
    opacity: 1;
    transition: all .25s ease;
    transform: translateY(0px);
  }

  .PageTransition-enter {
    opacity: .001;
    transform: translateY(15px);
    z-index: 0;
    width: 100%;
    position: absolute;
    top:0px;
  }

  .PageTransition-enter.PageTransition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all .25s ease;
    transition-delay: .5s;
    top:0px;
  }

  .PageTransition-leave {
    transform: translateY(0px);
    z-index: 1;
    position: relative;
    width:100%;
    height: auto;
    min-height: 110vh;
    top:0px;
    opacity: 1;
  }

  .PageTransition-leave.PageTransition-leave-active {
    transform: translateY(-15px);
    transition: all 250ms ease;
    opacity: 0;
    top:0px;
  }
  @keyframes SlideOut{
    0%{transform:translateX(0vw);opacity: 0;}
    40%{transform:translateX(0vw);opacity: 1;}
    50%{transform:translateX(0vw);opacity: 1;}
    100%{transform:translateX(110vw) skew(-15deg); opacity: 1;}
  }
  @keyframes SlideOutHome{
    0%{transform:translateX(0vw);opacity: 0;}
    40%{transform:translateX(0vw);opacity: 1;}
    50%{transform:translateX(0vw);opacity: 1;}
    100%{transform:translateX(110vw) skew(-15deg); opacity: 1;}
  }
  @include mobile{
    @keyframes SlideOut{
      0%{transform:translateX(0vw);opacity: 0;}
      10%{transform:translateX(0vw);opacity: 0;}
      40%{transform:translateX(0vw);opacity: 1;}
      50%{transform:translateX(0vw);opacity: 1;}
      100%{transform:translateX(130vw) skew(-20deg); opacity: 1;}
    }
    @keyframes SlideOutHome{
      0%{transform:translateX(0vw);opacity: 0;}
      10%{transform:translateX(0vw);opacity: 0;}
      40%{transform:translateX(0vw);opacity: 1;}
      50%{transform:translateX(0vw);opacity: 1;}
      100%{transform:translateX(130vw) skew(-20deg); opacity: 1;}
    }
  }
}
