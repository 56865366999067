@import '../../variables.scss';

#Industries{
  .hero-wrap{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .content-wrap{
      position: relative;
      padding-top: 170px;
      @include tablet{
        padding-top: 224px;
      }
      @include mobile{
        padding-top: 107px;
      }
    }
    .background-img{
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      z-index: -1;
      top:0px;
      left: 0px;
    }
    span{

    }
  }
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }

  h1{
    @include font-bold;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .small{
    width: 43%;
    display: inline-block;
    vertical-align: top;
    margin: 0vh 14% 7vh 0px;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      max-width: 44%;
      margin-right: 6%;
    }
    @include tablet{
      width: 100%;
      margin: 0vh 0% 3vh 0px;
    }
    @include mobile{
      margin: 4vh 0px;
      width: 100%;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 100%;
        margin-right: 0%;
      }
    }
    &:last-of-type{
      margin-right: 0px;
    }
    &:hover{
      img{
        transform: scale(1.025);
      }
      .main-img:before{
        left: 85%;
        top:85%;
        transition: all ease .5s .0s;
      }
      .main-img .container{
        transition-delay: .25s;
        .case-study-link{
          transition-delay: .25s;
          opacity: 1;
          transform: translateX(10px);
        }
      }
      .case-study-link{
        transform: translateX(60px);
        opacity: 0;
      }
    }
    .post-content{
      z-index: 5;
      cursor: pointer;
      h2{
        font-size: 36px;
        line-height: 38px;
        @include mobile{
          font-size: 26px;
          margin-bottom: 10px;
          line-height: 30px;
        }
      }
      h4{
        @include mobile{
          margin-top: 0px;
        }
      }
    }
    @include mobile{
      width: 100%;
    }
    .main-img{
      cursor: pointer;
      overflow: hidden;
      height: 0px;
      width: 100%;
      padding-bottom: 72.5%;
      position: relative;
      &:before{
        content: "";
        width:50%;
        height: 50%;
        position: absolute;
        left: 105%;
        top: 100%;
        transform: rotate(45deg);

        transition: all ease .5s .15s;
        z-index: 2;
      }
      img{
        transition: all ease .5s;
      }
      .container{
        width:16px;
        height:16px;
        position:absolute;
        display:block;
        top: 92%;
        left: 92%;
        z-index: 5;
        transition: all ease .25s .0s;
        .case-study-link{
          margin-top: 0px;
          opacity: 0;
        }
      }
    }
  }
  .featured-case-studies{

    .title{
      margin-top: 0px;
      padding-top: 100px;
      font-size: 45px;
      @include mobile{
        font-size: 28px;
      }
    }
    .post-content{
      text-align: center;
      h2{

        font-size: 24px;
        margin-bottom: 0px;
      }
      h4{
        text-transform: none;
        font-size: 16px;
        line-height: 20px;
        margin-top: 0px;

      }
    }
  }
  .description{
    background-color: #f7f7f7;
    padding: 40px 0px 150px;
    position: relative;
    overflow: hidden;
    margin-top: -5px;
    @include mobile{
      padding: 50px 0px;
    }
    .intro-copy{
      width:75%;
      font-size: 26px;
      line-height: 36px;
      color:$color-black;
      display: block;
      @include desktop{
        width:85%;
      }
      @include tablet{
        width: 100%;
        font-size: 23px;
        line-height: 32px;
      }
      @include mobile{
        font-size: 20px;
        line-height: 28px;
        width: 100%;
      }
      &:after{
        content:"\00d7";
        font-size: 22px;
        display: block;
        margin-top: 70px;
        margin-bottom: 40px;
        @include mobile{
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }
    .body{
      @include mobile{
        padding-bottom: 40px;
      }
      li{
        font-size: 20px;
        line-height: 32px;
        text-indent: -30px;
        @include mobile{
          padding-bottom: 15px;
          font-size: 18px;
          line-height: 24px;
        }
      }
      p{
        display: block;
        width:100%;
        margin-right: 5%;
        color:$color-black;
        margin-bottom: 40px;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
          margin-right: 0px;
          margin-bottom: 0px;
        }
        &:last-of-type{
          margin-right: 0px;
          @include mobile{
            margin-bottom: 0px;
          }
        }
      }
      .item-list{
        float: right;
        width: 35%;
        background: white;
        padding: 5% 0% 5% 7%;
        box-shadow: -15px 15px 25px rgba(0,0,0,.06);
        box-sizing: border-box;
        ul{
          padding-left: 0px;
          width:100%;
          margin-bottom: 50px;
          grid-row-gap:0px;
          @include tablet{
            grid-template-columns: repeat(2, 50%);
          }
          @include mobile{
            grid-template-columns: repeat(1, 100%);

          }
        }
        li{
          font-size: 17px;

          color:$color-white;
          margin-bottom: 10px;
          display: block;
          color: $color-black;
          &:before{
            display: none;
          }
          h3{
            margin: 0px 0px 0px 0px;
            display: inline-block;
            line-height: 25px;
            vertical-align: top;
          }
        }
      }
    }
  }
  .client-logos-wrap{
    float: right;
    vertical-align: top;
  }
  .body{
    float: left;
    width: 55%;
    margin-right: 5%;
    @include mobile{
      float: none;
      width:100%;
      margin-right:0px;
    }
  }
  .client-logos-wrap,
  .client-content{
    display: inline-block;
    width: 40%;
    vertical-align: middle;
    @include mobile{
      float: none;
      width:100%;
    }
  }
  .client-logos{
    width:90%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: -15px 15px 25px -5px rgba(0,0,0,0.06);
    .logo{
      width: 50%;
      display: inline-block;
      height: 16vh;
      background-color: $color-white;
      border: .5px solid $color-grey;
      box-sizing: border-box;
      vertical-align: top;
      position: relative;
      margin: -1px;
      .image{
        max-width: 120px;
        min-width: 50px;
        max-height: 120px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  #client-experience{
    background-color: $color-white;
    padding: 20vh 0px 10vh;
    @include mobile{
      padding-top: 5vh;
      padding-bottom: 0px;
    }
    h2{
      color:$color-white;
      text-align: center;
      margin-bottom: 50px;
      @include mobile{
        margin-bottom: 25px;
      }
    }
    .experience-content{
      width:50%;
      display: inline-block;
      vertical-align: top;
      padding-left: 5%;
      @include mobile{
        width: 100%;
      }
      h2, p{
        text-align: left;
        color: $color-black
      }
      h2{
        line-height: 60px;
        @include mobile{
          line-height: inherit;
        }
      }
    }
    .client-list{
      margin: 60px auto;
      display: inline-block;
      position: relative;
      width:30%;
      margin-left: 15%;
      @include tablet{
      }
      @include mobile{
        width: 100%;
        margin-top: 20px;
        margin-left: 30px;
        margin-bottom: 0px;
      }
      a{
        color:$color-black;

        font-size: 20px;
        text-decoration: none;
        line-height: 20px;
        position: relative;
        padding-bottom: 5px;
        display: block;
        width: auto;
        vertical-align: top;
        &:hover{
          span{
            &:after{
              animation:lineSlide .5s ease forwards;
            }
            &:before{
              transform: translateX(0px);
              opacity: 1;
            }
          }
        }
        &.active{
          color: $color-darkgrey;
          span{
            &:after{ opacity: 0;}
          }
        }
      }
      @keyframes lineSlide{
        0%{transform: translateX(0%);}
        49%{transform: translateX(-100%);}
        50%{transform: translateX(100%);}
        100%{transform: translateX(0%);}
      }
      span{
        width: auto;
        display: inline-block;
        overflow: hidden;
        transition:  all ease .25s;
        &:after{
          content:'';
          height: 2px;
          width: 100%;

          position: relative;
          display:  inline-block;
          vertical-align: top;
          margin-top: 5px;
          transition:  all ease .25s;
        }
        &:before{
          content:'';
          background-image: url(../../../public/img/arrow-grey.svg);
          background-repeat: no-repeat;
          width: 15px;
          height: 10px;
          position: absolute;
          left: -20px;
          top:5px;
          opacity: 0;
          transform: translateX(-10px);
          transition: all ease .25s;
        }
      }
    }
  }
  .cac-pannel-wrap{
    @include mobile{
      top:90%;
    }
  }
  .cac-pannel{
    animation-duration: 150s;
  }
  .hidden{
    .experience-content, .client-list, .small, .title{
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .fade-in-element{
    .experience-content, .client-list, .small, .title{
      transition:  all ease .75s .25s;
      opacity: 1;
      transform: translateY(0px);
    }
    .client-list, .small:nth-of-type(2){
      transition-delay: .5s;
    }
  }
}
