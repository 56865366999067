@import '../../variables.scss';

#capability{
  .hero-wrap{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .content-wrap{
      position: relative;
      padding-top: 150px;
      @include tablet{
        padding-top: 224px;
      }
      @include mobile{
        padding-top: 100px;
      }
    }
    .background-img{
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      z-index: -1;
      top:0px;
      left: 0px;
    }
    span{
    }
  }
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  h1{
    @include font-bold;
    font-size: 40px;
    line-height: 50px;
    width:70%;
    color: $color-white;
    margin-top: 0px;
    margin-bottom: 80px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .small{
    width: 43%;
    display: inline-block;
    vertical-align: top;
    margin: 0vh 14% 7vh 0px;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      max-width: 44%;
      margin-right: 6%;
    }
    @include tablet{
      width: 100%;
      margin: 0vh 0% 3vh 0px;
    }
    @include mobile{
      margin: 4vh 0px;
      width: 100%;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 100%;
        margin-right: 0%;
      }
    }
    &:last-of-type{
      margin-right: 0px;
    }
    &:hover{
      img{
        transform: scale(1.025);
      }
      .main-img:before{
        left: 85%;
        top:85%;
        transition: all ease .5s .0s;
      }
      .main-img .container{
        transition-delay: .25s;
        .case-study-link{
          transition-delay: .25s;
          opacity: 1;
          transform: translateX(10px);
        }
      }
      .case-study-link{
        transform: translateX(60px);
        opacity: 0;
      }
    }
    .post-content{
      z-index: 5;
      cursor: pointer;
      h2{
        font-size: 36px;
        @include mobile{
          font-size: 26px;
          margin-bottom: 10px;
          line-height: 30px;
        }
      }
      h4{
        @include mobile{
          margin-top: 0px;
        }
      }
    }
    @include mobile{
      width: 100%;
    }
    .main-img{
      cursor: pointer;
      overflow: hidden;
      height: 0px;
      width: 100%;
      padding-bottom: 72.5%;
      position: relative;
      &:before{
        content: "";
        width:50%;
        height: 50%;
        position: absolute;
        left: 105%;
        top: 100%;
        transform: rotate(45deg);
        background-color:$color-white;
        transition: all ease .5s .15s;
        z-index: 2;
      }
      img{
        transition: all ease .5s;
      }
      .container{
        width:16px;
        height:16px;
        position:absolute;
        display:block;
        top: 92%;
        left: 92%;
        z-index: 5;
        transition: all ease .25s .0s;
        .case-study-link{
          margin-top: 0px;
          opacity: 0;
        }
      }
    }
  }
  .other_capabilities{
    background-color: #f7f7f7;
    .caps-slide{
      @include mobile{
        width:245vw;
      }
    }
    .capabilities_home{
      width: 31%;
      margin-right: 2%;
      @include tablet{
        width: 48%;
      }
      @include mobile{
        width: 75vw;
        margin-right: 1.5%;
      }
      li{
        margin: 0px;
        text-indent: 0px;
        &:before{
          display: none;
        }
        &:first-of-type{
          padding-top: 20px;
        }
      }
    }
    .title{
      margin-top: 0px;
      padding-top: 100px;
      font-size: 45px;
      @include mobile{
        line-height: 45px;
        font-size: 38px;
      }
    }
  }
  .description{
    padding: 40px 0px 150px;
    position: relative;
    overflow: hidden;
    margin-top: -5px;
    background-color: $color-white;
    @include mobile{
      padding: 20px 0px;
    }
    .intro-copy{
      width:75%;
      font-size: 26px;
      line-height: 36px;
      color:$color-black;
      display: block;
      @include desktop{
        width:85%;
      }
      @include tablet{
        width: 100%;
        font-size: 23px;
        line-height: 32px;
      }
      @include mobile{
        font-size: 20px;
        line-height: 28px;
        width: 100%;
      }
      &:after{
        content:"\00d7";

        font-size: 22px;
        display: block;
        margin-top: 70px;
        margin-bottom: 40px;
        @include mobile{
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }
    .body{
      p{
        display: block;
        margin-right: 5%;
        color:$color-black;
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 40px;
        vertical-align: top;
        @include tablet{
          width:100%;
        }
        @include mobile{
          width:100%;
          margin-right: 0px;
          margin-bottom: 0px;
          font-size: 20px;
          line-height: 28px;
        }
        &:last-of-type{
          margin-right: 0px;
          @include mobile{
            margin-bottom: 40px;
          }
        }
      }
      .mainbody{
        float: left;
        width: 55%;
        margin-right: 5%;
        @include mobile{
          float: none;
          width: 100%;
        }
      }
      .item-list{
        float: right;
        width: 35%;
        background: white;
        padding: 5% 10px 5% 7%;
        box-shadow: -15px 15px 25px rgba(0,0,0,.06);
        box-sizing: border-box;
        @include mobile{
          width:100%;
          float: none;
          margin-bottom: 40px;
        }
        ul{
          padding-left: 0px;
          width:100%;
          margin-bottom: 0px;
          grid-row-gap:0px;
          @include tablet{
            grid-template-columns: repeat(2, 50%);
          }
          @include mobile{
            grid-template-columns: repeat(1, 100%);

          }
        }
        li, h2{
          font-size: 17px;
          line-height: 20px;

          color:$color-white;
          margin-bottom: 10px;
          margin-top: 0px;
          font-weight: 400;
          display: block;
          color: $color-black;
          &:before{
            display: none;
          }
          h3{
            margin: 0px 0px 0px 0px;
            display: inline-block;
            line-height: 25px;
            vertical-align: top;
          }
        }
      }
    }
  }
  .hero{
    img{
      width: 100%;
    }
  }
  .cac-pannel{
    animation-duration: 60s;
  }
  .hidden{
      .mainbody, .item-list, .title, .caps-list{
        opacity: 0;
        transform: translateY(20px);
      }
  }
  .fade-in-element{
    .mainbody, .item-list, .title, .caps-list{
      opacity: 1;
      transform: translateY(0px);
      transition: all ease .75s .25s;
    }
    .item-list, .caps-list{
      transition-delay: .5s;
    }
  }
}
