@import '../../variables.scss';

#team_member{
  padding-top: 150px;
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  h1{
    @include font-bold;
    font-size: 40px;
    line-height: 50px;
    color: $color-white;
    margin-top: 0px;
    margin-bottom: 0px;
    @include tablet{
      margin-top: 20px;
    }
    @include mobile{
      margin-top: 20px;
    }
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .body-wrap{
    background-color: $color-white;
    padding-top: 40px;
  }
  .type-wrap, .member-image{
    width:45%;
    display: inline-block;
    margin-right: 10%;
    vertical-align: top;
    @include tablet{
      width: 100%;
    }
    @include mobile{
      width: 100%;
    }
    &:last-of-type{
      margin-right: 0px;
    }
    h2{
      margin-bottom: 45px;
      margin-top: 0px;
      text-transform: none;
      font-size: 18px;
      line-height: 24px;
      color: $color-white;
      @include font-bold;
      @include tablet{
        margin-bottom: 35px;
        margin-top:0px;
      }
      @include mobile{
        margin-bottom: 35px;
        margin-top:0px;
      }
    }
    em{
      margin-bottom: 20px;
      display: block;
      font-size: 25px;
      line-height: 30px;

    }
    .body{
      margin-bottom: 100px;
    }
  }
  .member-image{
    float: right;
    margin-right: 0px;
    margin-top: -37px;
    -webkit-filter: greyscale(100%);
    filter: grayscale(100%);
    @include tablet{
      float:none;
      margin-top: 0px;
    }
    @include mobile{
      float:none;
      margin-top: 0px;
    }
  }
}
