@import '../../variables.scss';
#work{
  .bodyWrap{
    padding-top: 160px;
    background-color: $color-white;
  }
  .content-wrap{
    transition: all ease .5s 0s;
    h1{
      margin-bottom:15px;
      letter-spacing: -1px;
      &:after{
        content:'';
        margin-left: 7px;
        margin-top: 10px;
        width: 5px;
        height: 5px;
        background-color: $color-red;
        display: inline-block;
        vertical-align: middle;
      }
    }
    h3{
      @include font-book;
      max-width: 75%;
      &:before{
        content:'';
        width:20px;
        background: $background-gradient;
        height: 3px;
        display: block;
        position: relative;
        border-radius: 5px;
        margin: 0px 0px 15px;
      }
      @include mobile{
        max-width: 100%;
      }
    }
    &.hidden{
      opacity: 0;
      transform: translateY(20px);
    }
    &.fade-in-element{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .text-link{
    margin-bottom: 2vh;
    .breadcrumb{
      color: $color-black;
    }
    &:before{
      opacity: 1;
    }
    &:hover{
      &:before{
        opacity: 1;
      }
    }
    @include mobile{
      &.desktop{
        display: none;
      }
    }
  }
  span.breadcrumb{
    font-size: 14px;
    color: $color-green;
    text-transform: uppercase;
  }
  h1{
    margin-top: 0px;
    @include tablet{
      margin-top:1px;
    }
    @include mobile{
      margin-top: 1px;
    }
  }
  .client-experience{

    position: relative;
    overflow: hidden;
    padding: 10vh 0px;
    @include mobile{
      padding-top: 5vh;
    }
  }
  .client-logos-wrap,
  .client-content{
    display: inline-block;
    width: 40%;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    float: left;
    @include mobile{
      float: none;
      width: 100%;
    }
  }
  .client-content{
    margin-left: 10%;
    width: 50%;
    float: right;
    margin-top: 8vw;
    @include mobile{
      float: none;
      width: 100%;
      margin: 0px;
    }
    h3{
      font-size: 36px;

    }
    p{
      margin-bottom: 40px;
    }
  }
  .cac-pannel-wrap{
    z-index: 1;
  }
  .client-logos{
    width:90%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: -15px 15px 25px -5px rgba(0,0,0,0.06);
    .logo{
      width: 50%;
      display: inline-block;
      height: 16vh;
      background-color: $color-white;
      border: .5px solid $color-grey;
      box-sizing: border-box;
      vertical-align: top;
      position: relative;
      margin: -1px;
      .image{
        max-width: 120px;
        min-width: 50px;
        max-height: 120px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .logoRotator{
    width:90%;
    margin: 0 auto;
    .slick-slide{
      img{
        min-width: 60%;
        width: 60%;
        margin: 0 auto;
        margin-top: 50%;
        position: relative;
        transform: translateY(-50%);
      }
    }
    .slick-arrow{
      &.slick-next{
        right:-5%;
      }
      &.slick-prev{
        left:-5%;
      }
    }
  }
  .case_studies{
    margin: 0 auto;
    position: relative;
    padding-bottom: 120px;
    column-count: 2;
    column-gap: 6%;
    @include mobile{
      column-count: 1;
    }
    h4{
      margin-top: -10px;
      display: block;
      font-size: 20px;
      text-transform: none;
      @include mobile{
        text-align: center;
        font-size: 14px;
      }
    }
    &.client-work{
      .small{
        display: block;
        &:nth-of-type(2){
          margin-top: 200px;
          @include mobile{
            margin: 2vh 0px;
          }
        }
      }
    }
  }
  .small{
    display: inline-block;
    vertical-align: top;
    margin: 7vh 0px 0vh 0px;
    position: relative;
    text-align: center;
    backface-visibility: hidden;
    break-inside: avoid-column;
    &:nth-of-type(4){
      margin-top: 200px;
      @include mobile{
        margin: 2vh 0px;
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      max-width: 44%;
      margin-right: 6%;
    }
    @include mobile{
      margin: 2vh 0px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 100%;
        margin-right: 0%;
      }
    }
    .post-content{
      -webkit-transform: translate3d(0, 0, 0);
      z-index: 5;
      cursor: pointer;
      text-align: left;
      h4{
        @include font-book;
        line-height: 26px;
        margin-top:20px;
        @include mobile{
          margin-top: 0px;
        }
      }
    }
    @include mobile{
      width: 100%;
    }
    .main-img{
      -webkit-transform: translate3d(0, 0, 0);
      cursor: pointer;
      overflow: hidden;
      height: auto;
      width: 100%;
      position: relative;
      border-radius: 7px;
      &:before{
        content: "";
        width:300px;
        height: 300px;
        position: absolute;
        left: 105%;
        top: 100%;
        transform: rotate(45deg);
        background-color:$color-white;
        transition: all ease .5s .15s;
        z-index: 2;
        pointer-events: none;
      }
      img{
        transition: all ease .5s;
      }
      .container{
        width:16px;
        height:16px;
        position:absolute;
        display:block;
        bottom: 25px;
        left: 92%;
        z-index: 5;
        transition: all ease .25s .0s;
        .case-study-link{
          margin-top: 0px;
          opacity: 0;
        }
      }
    }
    .title{
      display: none;
      margin: 10px auto 0px;
      text-align: center;
      @include font-bold;
      color: $color-black;
      @include mobile{
        display: block;
      }
    }
  }
  .awards{
    text-align: center;
    padding: 120px 0px;
    @include mobile{
      padding-top: 60px;
    }
    .wrap{
      display: grid;
      grid: 25% / auto auto auto;
      grid-auto-flow: column dense;
      grid-template-rows: auto auto auto auto;
      margin: 0 auto;
      grid-gap: 3%;
      @include mobile{
        grid: 100% / auto;
      }
    }
    h2{

      font-size: 46px;
      @include mobile{
        font-size:32px;
        line-height: 38px;
      }
    }
    p{
      margin:55px auto 100px;
      max-width: 850px;
      font-size: 26px;

      color: $color-black;
      @include mobile{
        font-size:20px;
        line-height: 26px;
        margin-bottom: 50px;
      }
    }
    span{
      display: block;
      text-align: left;

      color:$color-blue;
      padding: 10px 0px;
      font-size: 20px;
      @include mobile{
        font-size:18px;
        line-height: 22px;
        text-align: center;
      }
    }
    br{
      clear: both;
    }
  }
  .hidden{
    &.small, .client-content, .client-logos-wrap{
      opacity: 0;
      transform: translateY(20px);
      pointer-events: none;
    }
  }
  .fade-in-element{
    &.small{
      transition:  all ease .5s .15s;
      opacity: 1;
      transform: translateY(0px);
    }
    .client-content, .client-logos-wrap{
      transition:  all ease .75s .25s;
      opacity: 1;
      transform: translateY(0px);
    }
    .client-logos-wrap{
      transition-delay: .5s;
    }
  }
}
