@import "../../variables.scss";

.toolkit-modal.active {
  // display: block;
  opacity: 1;
  z-index: 10;
}

.toolkit-modal {
  opacity: 0;
  z-index: -1;
  height: calc(100% + 100px);
  width: 100%;
  top: -100px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.69);
  transition: opacity, 0.2s ease-in;
  -webkit-transition: opacity, 0.2s ease-in;
  -moz-transition: opacity, 0.2s ease-in;
  -o-transition: opacity, 0.2s ease-in;

  .form-wrap {
    position: absolute;
    background-color: $color-white;
    max-width: 600px;
    z-index: 11;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);

    @include tablet {
      width: 85%;
    }

    @include mobile {
      width: 85%;
    }

    .form {
      width: 85%;
      margin: 0 auto;
      border-radius: 2px;

      h3 {
        text-align: center;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 21px;

        @include tablet {
          font-size: 26px;
        }

        @include mobile {
          font-size: 22px;
        }
      }

      p.desc {
        color: #595959;
        font-size: 18px;
        margin-bottom: 40px;
        margin-top: 0;
        line-height: 30px;

        a {
          color: #018093;
        }

        @include tablet {
          font-size: 16px;
        }

        @include mobile {
          font-size: 16px;
        }
      }

      label {
        position: absolute;
        top: 50%;
        left: 21px;
        font-size: 22px;
        transform: translate(0, -50%);
        color: #979593;
        z-index: 12;
      }

      label.active {
        display: none;
      }

      .input {
        position: relative;
      }

      input {
        width: 100%;
        height: 51px;
        font-size: 22px;
        padding-left: 21px;
        box-sizing: border-box;
        // touch-action: manipulation; // These options make pw field not work on mobile
        // -webkit-user-select: none;
        // -moz-user-select: none;
        // -ms-user-select: none;
        // user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
      }

      input#pw {
        background-color: #dadada;
      }

      #submit {
        width: 100%;
        height: 51px;
        background-color: $color-green;
        margin-bottom: 21px;
        font-size: 22px;
      }

      #submit:hover {
        color: $color-white;
      }

      .error-wrap {
        height: 30px;
        position: relative;

        .errors.active {
          display: block;
          color: #d9534f;
        }
        .errors {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          display: none;
        }
      }
    }
  }
}

#pw.invalid {
  // outline-color: red;
  /* also need animation and -moz-animation */
  -webkit-animation: shake 0.5s linear;
}

/* also need keyframes and -moz-keyframes */
@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-5px);
  }
  25%,
  58% {
    -webkit-transform: translateX(5px);
  }
  75% {
    -webkit-transform: translateX(-2px);
  }
  92% {
    -webkit-transform: translateX(2px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

//Some Bootstrap CSS
button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.btn {
  font-size: 14px;
  padding: 6px 12px;
  margin-bottom: 0;
  border-radius: 4px;
  color: $color-white;
  @include font-bold;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}
.btn:focus,
.btn:active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

/* default
---------------------------- */
.btn-default {
  color: $color-white;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus {
  color: $color-white;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: $color-white;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active {
  color: $color-white;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  // border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
