@import '../../variables.scss';

#job_listing{
padding-top: 150px;
  @include mobile{
    padding-top: 93px;
    .cac-pannel-wrap{
      display: none;
    }
  }
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  .postBody{
    padding-top: 5vh;
    background-color: $color-white;
    .desc{
      max-width: 800px;
      margin-left: 0px;
      p{
        @include font-book;
      }
    }
    a{
      position: relative;
      @include gradient-color;
      &:after{
        content:'';
        width:0px;
        height: 2px;
        position: absolute;
        bottom:-5px;
        display: block;
        transition: all ease .25s;
        background: $background-gradient;
        left: 0px;
        @include mobile{
          display: none;
        }
      }
      &:hover{
        &:after{
          width:100%;
        }
      }
    }
  }
  .type-wrap{
    width:65%;
    display: inline-block;
    margin-right: 10%;
    vertical-align: top;
    @include desktop{
      width:70%;
    }
    @include tablet{
      width: 100%;
    }
    @include mobile{
      width: 100%;
    }
    &:last-of-type{
      margin-right: 0px;
    }
    h1{
      @include font-bold;
      color: $color-white;
      margin-top: 0px;
      &:after{
        content: '';
        margin-left: 10px;
        margin-top: 5px;
        width: 8px;
        height: 8px;
        background-color: #EB1946;
        display: inline-block;
        vertical-align: middle;
      }
    }
    a{
      color: $color-black;
      text-decoration: underline;
    }
  }
  .content-wrap{
    padding-bottom: 50px;
    &.form{
      padding-bottom: 0px;
    }
  }
  h3{
    font-size: 36px;
    line-height: 48px;
    color: $color-black;
    @include font-bold;
    margin-top: 40px;
    @include mobile{
      margin-bottom: 10px;
      font-size: 28px;
    }
  }
  .roles{
    display: block;
    width:65%;
    display: inline-block;
    margin-right: 10%;
    vertical-align: top;
    @include desktop{
      width:70%;
    }
    @include tablet{
      width: 100%;
    }
    @include mobile{
      width: 100%;
    }
    li{
      color: $color-black;
      padding-left: 30px;
      text-indent: -30px;
      font-size: 18px;
      line-height: 26px;
      @include mobile{
        line-height: 25px;
        margin-bottom: 10px;
      }
    }
  }
  #job_form{
    background-color: #f7f7f7;
    padding: 100px 0px;
  }
  .form{
    p.noshow{
      display: none;
    }
    .row{
      margin-bottom: 40px;
      label{
        width:30%;
        display: inline-block;
        color: $color-black;
        font-size: 20px;
        @include mobile{
          width: 100%;
          margin-bottom: 10px;
        }
      }
      input{
        width: 69.25%;
        display: inline-block;
        background-color:$color-white;
        border:none;
        color:$color-black;
        padding: 10px 10px 10px;
        box-sizing: border-box;
        height: 45px;

        font-size: 20px;
        border:1px solid $color-white;
        transition: all ease .25s;
        @include mobile{
          width: 100%;
        }
        &:focus{
          border: 1px solid ;
          outline-color: transparent;
          outline: 0px;
        }
      }
      .input-file{
        opacity: 0;
        position: absolute;
        left: -100000%;
        height: 1px;
        width: 1px;
        &:focus + label{
          border: 1px solid ;
        }
      }
      .outline-label{
        background-color:$color-white;
        display: inline-block;
        opacity: 1;
        width:100%;
        text-align: center;
        height: 30px;
        padding: 5px 0px;

        color: #949495;
        border: 1px solid $color-white;
        transition: all ease .25s;
        &.active{
          color:$color-black;
        }
        &:hover{
          opacity: .8;
        }
      }
      .box{
        display: inline-block;
        width: 31.5%;
      }
      .block{
        display: block;
        label{
          background-color: $color-white;
          text-transform: uppercase;
          font-size: 14px;
          padding-top: 15px;
          margin-bottom: 20px;
          position: relative;
          cursor: pointer;
          &:after{
            content:"";
            position: absolute;
            right:10px;
            top:15px;
            z-index: 10;
            font-size: 24px;

            background-image: url(../../../public/img/check-inactive.svg);
            background-size: contain;
            width:20px;
            height: 20px;
          }
        }
        .outline-label{
          &.active{

            &:after{
              background-image: url(../../../public/img/check-active.svg);
            }
          }
        }
      }
      .col{
          width:30%;
          @include mobile{
            width: 100%;
            font-size: 18px;
          }
      }
      .col-2{
        width:69.25%;
        display: inline-block;
        vertical-align: top;
        @include mobile{
          width: 100%;
        }
        .block{
          width:44%;
          @include tablet{
            width: 100%;
          }
          @include mobile{
            width: 100%;
          }
        }
      }
    }
    .portfolio{
      position: relative;
/*      input{
        width: 30.5%;
        @include mobile{
          width: 100%;
        }
      }
      .or{
        padding:0 3%;

        color: $color-black;
        opacity: .45;
        font-size: 20px;
      }
      */
      label{
        .tooltip{
          position: absolute;
          width: 14px;
          height: 14px;
          left: -20px;
          top:0px;
          display: block;
          &:hover{
            &:before{
              opacity: 1;
              transform: translateY(0px);
              left: 20px;
              transition: opacity ease .25s, transform ease .25s, left ease 0s 0s;
            }
          }
          &:after{
            content: "";
            position: relative;
            display: inline-block;
            background-image: url(../../../public/img/info-icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
            opacity: .75;
          }
          &:before{
            content:"If you have a portfolio please link to it here. If it's in PDF format you can upload it to a free service such as google docs or dropbox, make it publicly available and provide a link.";
            width: 350px;
            height: auto;
            padding: 10px;
            background-color: $color-white;
            border: 2px solid $color-grey;
            box-shadow: 5px 5px 5px rgba(0,0,0,.45);
            font-size: 15px;

            display: block;
            position: absolute;
            left: -2000px;
            transition: opacity ease .25s, transform ease .25s, left ease 0s .25s;
            opacity: 0;
            transform: translateY(10px);
          }
        }
      }
    }
    .button[type=submit]{
      background:$color-white;
      border:solid 3px #E1E1E1;
      color:$color-black;
      opacity: 1;
      width: 100%;
      cursor: pointer;
      &:hover{
        color:$color-black;

        border:solid 3px ;
      }
      &:after{
        background-image: url(../../../public/img/arrow-black.svg);
      }
    }
  }
  #submitButton{
    &:disabled{
      pointer-events: none;
      &:after{
        display: none;
      }
      &:hover{
        background-color: $color-white;
        border:solid 3px #E1E1E1;
        color:$color-black;
      }
    }
  }
  .cac-pannel{
    animation-duration: 150s;
  }
}
