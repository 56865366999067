@import 'variables.scss';

* {
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  &:focus{
    outline: 0;
  }
}
//Global styles for all pages
html{
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
  max-width: 100vw;
  overflow-x: hidden;
  @include mobile{
    max-width: 100%;
    overflow-x: hidden;
  }
}
body{
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}
a{
  text-decoration: none;
}
img{
  max-width: 100%;
}
h1{
  font-size: 50px;
  color: $color-black;
  line-height:70px;
  @include font-bold;
  @include tablet{
    font-size: 46px;
    line-height: 60px;
  }
  @include mobile{
    font-size: 32px;
    line-height: 38px;
  }
}
h2{
  font-size: 56px;
  color: $color-black;
  margin-bottom: 20px;
  line-height: 60px;
  @include font-book;
  @include tablet{
    font-size: 45px;
    line-height:50px;
  }
  @include mobile{
    font-size: 25px;
    line-height: 30px;
  }
}
h3{
  font-size: 28px;
  line-height: 32px;
  color: $color-black;
  @include font-bold;
  @include tablet{
    font-size: 22px;
    line-height:28px;
  }
  @include mobile{
    font-size: 18px;
    line-height: 23px;
  }
}
h4{
  @include font-medium;
  font-size: 14px;
  color: $color-black;
  text-transform: uppercase;
}

p{
  line-height: 34px;
  font-size: 20px;
  @include font-book;
  color: $color-black;
  @include mobile{
    font-size: 18px;
    line-height: 24px;
  }
}
em{
  font-size: 26px;
  line-height: 36px;
  color: $color-black;
  font-style: normal;
  @include font-book;
  font-style: italic;
}
li{
  line-height: 20px;
  font-size: 16px;
  list-style: none;
  color: #535353;
  padding-bottom: 15px;
  @include font-book;
  &:before{
    content:"•";
    font-size: 24px;
    padding-right: 20px;
  }
}
h1 + .text-link{
  max-width: 365px;
}

.crumb-link,
.crumb{
  display: inline-block;
  width: auto;
  color: $color-black;

  margin-bottom: 2rem;
  @include mobile{
    margin-bottom: 1rem;
  }
  span{
    color: $color-black;
    display: inline-block;
    position: relative;
    font-size: 18px;
    &:first-of-type{
      margin-right: 5px;
      &:after{
        content:'';
        width: 100%;
        height: 2px;

        position: absolute;
        left: 0px;
        top:25px;
      }
      &:before{
        content:'';
        width: 20px;
        height: 20px;
        position: absolute;
        left: -25px;
        top:-5px;
        background-image: url(../public/img/arrow-grey.svg);
        background-repeat: no-repeat;
        transform: rotate(180deg) translateX(-3px);
        opacity: 0;
        transition: all ease .25s;
      }
    }
  }
  &:hover{
    span{
      &:first-of-type{
        &:before{
          opacity: 1;
          transform: rotate(180deg) translateX(3px);
        }
      }
    }
  }
  + h1{
    margin-top: 0px;
  }
}
.crumb{
  span{
    &:after,
    &:before{
      display: none;
    }
  }
}
.pagewrap{
  width:100%;
}
.content-wrap{
  max-width: 1300px;
  margin: 0 auto;
  width: 90%;
  @include mobile{
    width: 85%;
  }
}
//display proper image based on screen size
.desktop-img,
.desktop{
  display:block;
}
.mobile-img,
.mobile{
  display:none;
}
@include tablet{
  .desktop-img{
    display:none;
  }
  .mobile-img{
    display:block;
  }
}

@include mobile{
  .desktop-img,
  .desktop{
    display:none;
  }
  .mobile-img,
  .mobile{
    display:block;
  }
}

//social Icons
.social{
  position: relative;
  float: right;
  width: 110px;
  a{
    background: $color-white;
    width: 30px;
    height: 30px;
    color: $color-black;
    position: relative;
    display: inline-block;
    border-radius: 30px;
    margin:0px 3%;
    transition: all ease .25s;
    @include mobile{
      margin: 0px 4px;
    }
    &:last-of-type{
      margin-right: 0px;
    }
    i{
      color: #7F899F;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
      transition: all ease .25s;
    }
    &:hover{
      transform: scale(1.15);

      i{
        color: $color-black;
      }
    }
  }
}

//card styles -capabilities and home pages
.capabilities_list{
  background-size: 0px;
  padding: 0px;
  height: auto;
  max-height: none;
  padding-bottom: 10vh;
  min-height: 0px !important;
  height: auto !important;
  &:after{
    display: none;
  }
  .background{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 100%;
    position: absolute;
    height: 65%;
    bottom: 0px;
    display: none;
    @include tablet{
      display: block;
    }
    @include mobile{
      display: block;
    }
  }
  .cap-wrap{
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .content-wrap{
    //animation:fadeOut .25s ease forwards;
    height: auto;
  }
  .intro-copy{
    transform:translateY(10px);
    transition: all ease .25s .35s;
    margin-bottom: 5vh;
    padding-top: 5vh;
    margin: 0 auto 5vh;
    text-align: center;
    @include desktop{
      width: 85%;
    }
    @media all and (max-width:1050px){
      width:80%;
      margin-bottom: 5vh;
    }
    @include tablet{
      width: 100%;
    }
    @include mobile{
      width:100%;
    }
    p{
      color: $color-black;

      font-size: 46px;
      margin: 0px;
      padding-top: 8vh;
      line-height: 51px;
      @media all and (max-width:1050px){
        font-size: 34px;
        line-height: 38px;
      }
      @include mobile{
        font-size: 22px;
        line-height: 25px;
        padding-top: 2vh;
      }
    }
  }
  span.foot{
    font-style: 13px;
    color:$color-white;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    @include mobile{
      bottom: 10%;
    }
    &:after{
      content: "";
      background-image: url(../public/img/arrow-white-down.svg);
      width:7px;
      height: 7px;
      position: relative;
      display: block;
      background-size: contain;
      margin: 5px auto 0px;
      background-repeat: no-repeat;
      animation: bounce ease 5s infinite;
    }
  }
}
.caps-slide{
  @include mobile{
    width:330vw;
    &.short{
      width:250vw;
    }
  }
}
.caps-list{
  @include mobile{
    width: 100vw;
    margin-left: -9%;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
.caps-list::-webkit-scrollbar{
  width: 0;
  height: 0;
}
.capabilities_home{
  display: inline-block;
  width:25%;
  background-color: transparent;
  vertical-align: top;
  padding: 2%;
  transition: all ease .5s;
  box-sizing: border-box;
  position: relative;
  @include tablet{
    width:50%;
    padding: 5%;
  }
  @include mobile{
    width:75vw;
    padding: 30px;
    background-color: $color-white;
    box-shadow: -15px 15px 25px -5px rgba(0,0,0,0.06);
    margin-bottom: 20px;
    margin-right: 1.5%;
    &:first-of-type{
      margin-left: 2%;
    }
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
  &:before{
    content:'';
    height: 2px;

    position: absolute;
    bottom: 0px;
    width: 0%;
    transition: all ease .25s;
    left: 50%;
    transform: translateX(-50%);
    @include mobile{
      width: 100%;
    }
  }
  &:after{
    content:'';
    background-repeat: no-repeat;
    background-image: url(../public/img/arrow-grey.svg);
    width:12px;
    height: 8px;
    position: absolute;
    top:40px;
    right: 30px;
    opacity: 0;
    transition: all ease .5s;
    background-size: cover;
    @include mobile{
      opacity: 1;
      transform: translateX(10px);
    }
  }
  &:hover{
    background-color: $color-white;
    box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.15);
    &:after{
      transform: translateX(10px);
      //opacity: 1;
    }
    &:before{
      width:100%;
    }
  }
  ul{
    padding: 0px;
    list-style: none;
    vertical-align: top;
    li{
      list-style: none;
      &:before{
        display: none;
      }
      h3{
        font-weight: 300;
        margin: 0px;
        font-size: 14px;
        line-height: 18px;
      }
      &:last-of-type{
        padding-bottom: 0px;
      }
    }
  }
  .header-wrap{
    max-width: 90%;
    display: block;
  }
  .header-link{
    font-size: 20px;
    line-height: 28px;
    color: $color-black;
    display: inline;
    margin: 0px;
    @include font-bold;
    letter-spacing: 0px;
    @media all and (max-width: 1000px){
      font-size: 18px;
      line-height: 26px;
    }
    @include mobile{
      font-size: 18px;
      line-height: 26px;
    }
    &:before{
      content:'';
      width:7px;
      height: 7px;
      position: absolute;
      left: 15px;
      top: 15px;
      display: block;
      background-color: $color-red;
    }
  }
}


//animation keyframes
@keyframes fadeOut {
  0% { transform: translateY(0px); opacity:1;}
  100% { transform: translateY(-15px); opacity:0; }
}
@keyframes fadeOut-Center{
  0% { transform: translate(-50%, 0px); opacity:1;}
  100% { transform: translateY(-50%, -15px); opacity:0; }
}
@keyframes fadeOutReverse {
  0% { transform: translateY(0px); opacity:1;}
  100% { transform: translateY(15px); opacity:0; }
}
@keyframes rotateObj {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}
@keyframes fadeIn {
  0% { transform: translateY(15px); opacity:0;}
  100% { transform: translateY(0px); opacity:1; }
}
@keyframes fadeIn-Center {
  0% { transform: translate(-50%, 15px); opacity:0;}
  100% { transform: translate(-50%, 0px); opacity:1; }
}
@keyframes fadeInOpacity {
  0% { opacity:0;}
  100% { opacity:1; }
}
