@import '../../variables.scss';

#posts{
  padding-top: 150px;
  min-height: 95vh;
  overflow-x: hidden;
  @include mobile{
    padding-top: 50px;
  }
  .crumb-link{
    @include gradient-color;
    @include font-bold;
    span{
      font-size: 15px;
    }
  }
  h1{
    @include font-bold;
    color: $color-white;
    margin-bottom: 80px;
    margin-top: 0px;
    &:after{
      content: '';
      margin-left: 10px;
      margin-top: 5px;
      width: 8px;
      height: 8px;
      background-color: #EB1946;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .body{
    background-color: $color-white;
    padding-bottom: 10vh;
  }
  .video-wrap{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
  .image-wrap{
    width: 100%;
    display: block;
    img{
      width: 100%;
      margin-bottom: -5px;
    }
    &.content-width{
      max-width: 1300px;
      margin: 0 auto;
      padding-top: 10vh;
      width: 90%;
      @include mobile{
        padding-top: 5vh;
      }
    }
  }
  .video-boxed{
    position: relative;
    padding-bottom: 57.25%;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top: 5%;
      left: 5%;
      border: 0;
      width: 90%;
      height: 90%;
    }
  }
  .text_block{
    max-width: 1200px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    padding: 100px 0px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // -webkit-hyphens: auto;
    // hyphens: auto;

    @include tablet{
      transform: translateX(0%);
      left: 0px;
      width: 90%;
      margin: 0 auto;
    }
    @include mobile{
      transform: translateX(0%);
      left: 0px;
      width: 90%;
      margin: 0 auto;
      padding: 50px 0px;
    }
    .content-wrap{
      width: 90%;
      @include tablet{
        width: 100%;
      }
      @include mobile{
        width: 100%;
      }
      p{
        a{
          position: relative;
          /*@include gradient-color;*/
          color:$color-green-darker;
          &:after{
            content:'';
            width:0px;
            height: 2px;
            position: absolute;
            bottom:-5px;
            display: block;
            transition: all ease .25s;
            background: $background-gradient;
            left: 0px;
            @include mobile{
              display: none;
            }
          }
          &:hover{
            &:after{
              width:100%;
            }
          }
        }
      }
    }
    ul{
      padding-left: 0px;
    }
    h2{
      font-size: 46px;
      margin-top: 100px;

      @include tablet{
        font-size: 36px;
      }
      @include mobile{
        font-size: 26px;
        line-height: 30px;
        margin-top: 50px;
      }
    }
  }
  .related-posts{
    .related-wrap{
      padding:50px 0px;
    }
    h3{
      color:$color-black;
      margin-bottom: 40px;
      text-align: center;
    }
    h4{
      margin-top: 10px;
      font-size: 30px;
      text-transform: none;
      @include mobile{
        font-size: 25px;
        margin-bottom: 15px;
      }
    }
    .news-card{
      width: 31.25%;
      margin: 0 1% 20px;
      display: inline-block;
      box-sizing: border-box;
      background-color:$color-white;
      transition: all ease .25s;
      vertical-align: top;
      @include tablet{
        width: 100%;
      }
      @include mobile{
        width: 100%;
      }
      .hide{
        display: none;
      }
      .featured_image{
        max-height: 250px;
        overflow: hidden;
        position: relative;
        img{
          width: 100%;
        }
      }
      .content{
        padding: 10%;
      }
      .case-study-link{
        margin-top: 30px;
        padding-bottom: 30px;
        background-size: 19px;
        width: 100%;
      }
    }
  }
}
