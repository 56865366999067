@import '../../variables.scss';
#Partnerships{
  .content-wrap{
    padding-top: 200px;
    .crumb{
      @include gradient-color;
      @include font-bold;
      margin-bottom: 0px;
    }
    h1{
      @include font-bold;
      color: $color-white;
      margin-bottom: 80px;
      margin-top: 0px;
      &:after{
        content: '';
        margin-left: 10px;
        margin-top: 5px;
        width: 8px;
        height: 8px;
        background-color: #EB1946;
        display: inline-block;
        vertical-align: middle;
      }
    }
    h3{
      color: $color-white;
      @include font-book;
      margin: 100px 0px;
      &:before{
        content:'';
        background: $background-gradient;
        height: 4px;
        width: 20px;
        display: block;
        margin-top:-40px;
        position: relative;
        margin-bottom: 20px;
      }
    }
    .button{
      float: right;
      padding: 15px 30px;
      @include tablet{
        display: none;
      }
      @include mobile{
        display: none;
      }
    }
  }
  .logos-wrap{
    background-color: $color-white;
    .content-wrap{
      max-width: 1300px;
      margin: 0 auto;
      padding: 10vh 0px 0vh;
    }
    .logo-wrap{
      display: inline-block;
      width:20%;
      vertical-align: middle;
      position: relative;
      text-align: center;
      @include tablet{
        width:33%;
      }
      @include mobile{
        width:50%;
      }
      img{
        width:60%;
        margin: 0 auto;
        vertical-align: middle;
      }
    }
  }

  .partners-wrap{
    background-color: $color-white;
    padding-bottom: 5vh;
    padding-top: 1vh;
    .partners-wrap{
      max-width: 1300px;
      padding: 0vh 0px 0vh;
      position: relative;
      margin: 15vh auto 10vh;
      @include tablet{
        margin: 10vh auto 10vh;
      }
      @include mobile{
        margin: 10vh auto 10vh;
      }
      &:before{
        content:'';
        position: absolute;
        z-index: 0;
        background-color: #F7F7F7;
        top:0vh;
        right: 0px;
        width:80%;
        height: 100%;
        border-radius: 5px;
        @include tablet{
          display: none;
        }
        @include mobile{
          display: none;
        }
      }
      .image,
      .content{
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        z-index: 5;
        position: relative;
      }
      .image{
        width:40%;
        border-radius: 5px 0px 0px 5px;
        overflow: hidden;
        img{
          display: block;
        }
        @include tablet{
          width: 90%;
          margin: 0 auto;
          display: block;
        }
        @include mobile{
          width: 90%;
          margin: 0 auto;
          display: block;        }
      }
      .content{
        width:40%;
        margin-left: 5%;
        @include tablet{
          width:90%;
        }
        @include mobile{
          width:90%;
        }
        h2{
          margin-bottom: -20px;
          @include font-bold;
          color: $color-black;
        }
        .title{
          @include font-light;
          margin-top: -30px;
          font-size: 15px;
          display: block;
          @include tablet{
            margin-top: -15px;
          }
          @include mobile{
            margin-top: -15px;
          }
        }
        .body{
          margin-bottom: 40px;
          p{
            font-size: 15px;
          }
        }
      }
      &:nth-of-type(2n){
        .image{
          float: right;
          border-radius: 0px 5px 5px 0px;
          @include tablet{
            float: none;
          }
          @include mobile{
            float: none;
          }
        }
        &:before{
          left: 0px;
        }
      }
      .button{
        padding: 15px 30px;
        @include mobile{
          display: block;
        }
      }
      .topics{
        background-color: $color-white;
        border-radius: 5px;
        padding: 5%;
        margin: 60px 0px;
        h3{
          margin-top: 0px;
        }
        ul{
          padding-left: 0px;
          li{
            &:before{
              display: none;
            }
          }
        }
      }
    }
    br{
      clear: both;
    }
  }
  .hidden{
    &.partners-wrap{
        opacity: 0;
        transform:translateY(20px);
        transition: all ease .25s;
    }
  }
  .fade-in-element{
    &.partners-wrap{
        opacity: 1;
        transform:translateY(0px);
        transition: all ease .5s .25s;
    }
  }
}
