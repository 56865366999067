@import '../../variables.scss';

#jobform{
  background-color: $color-grey;
  padding-top: 150px;
  p.noshow{
    display: none;
  }
  .type-wrap{
    width:65%;
    display: inline-block;
    margin-right: 10%;
    vertical-align: top;
    p{
      font-size: 26px;
      line-height: 36px;
      br {
        margin-bottom: 40px;
        &:first-of-type{
          display: none;
        }
      }
      &:after{
        content:"\00d7";

        margin:30px 0px 60px;
        display: block;
        position: relative;

        font-size: 35px;
      }
    }
    @include tablet{
      width: 100%;
    }
    &:last-of-type{
      margin-right: 0px;
    }
    h1{
      margin-bottom: 80px;
      span{
        display: block;
      }
    }
  }
  h3{

    font-size: 46px;
    color: $color-black;
    margin-top: 80px;
  }
  .roles{
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 45% 45%;
    grid-gap: 5%;
    margin-bottom: 100px;
    li{
    }
  }
  .form{
    .row{
      margin-bottom: 40px;
      label{
        width:30%;
        display: inline-block;

        color: $color-black;
        opacity: .45;
        font-size: 20px;
      }
      input{
        width: 69.25%;
        display: inline-block;
        background-color:$color-grey;
        border:none;
        color:$color-black;
        padding: 10px 10px 10px;
        box-sizing: border-box;
        height: 45px;

        font-size: 20px;
        border:1px solid $color-white;
        transition: all ease .25s;
        &:focus{
          border: 1px solid ;
          outline-color: transparent;
          outline: 0px;
        }
      }
      .input-file{
        opacity: 0;
        position: absolute;
        left: -100000%;
        height: 1px;
        width: 1px;
        &:focus + label{
          border: 1px solid ;
        }
      }
      .outline-label{
        background-color:$color-grey;
        display: inline-block;
        opacity: 1;
        width:100%;
        text-align: center;
        height: 30px;
        padding: 5px 0px;

        color: #949495;
        border: 1px solid $color-white;
        transition: all ease .25s;
        &.active{
          color:$color-black;
        }
        &:hover{
          opacity: .8;
        }
      }
      .box{
        display: inline-block;
        width: 31.5%;
      }
      .block{
        display: block;
        label{
          background-color: #E1E1E1;
          text-transform: uppercase;
          font-size: 14px;
          padding-top: 15px;
          margin-bottom: 20px;
          position: relative;
          cursor: pointer;
          &:after{
            content:"";
            position: absolute;
            right:10px;
            top:15px;
            z-index: 10;
            font-size: 24px;
            background-image: url(../../../public/img/check-inactive.svg);
            background-size: contain;
            width:20px;
            height: 20px;
          }
        }
        .outline-label{
          &.active{

            &:after{
              background-image: url(../../../public/img/check-active.svg);
            }
          }
        }
      }
      .col{
          width:30%;
      }
      .col-2{
        width:69.25%;
        display: inline-block;
        vertical-align: top;
        .block{
          width:44%;
        }
      }
    }
    .portfolio{
      /*input{
        width: 30.5%;
      }
      .or{
        padding:0 3%;

        color: $color-black;
        opacity: .45;
        font-size: 20px;
      }
      label{
        cursor: pointer;
      }*/
    }
    .button[type=submit]{
      background:$color-white;
      border:solid 3px #E1E1E1;
      color:$color-black;
      opacity: 1;
      cursor: pointer;
      &:hover{
        color:$color-black;

        border:solid 3px ;
      }
      &:after{
        background-image: url(../../../public/img/arrow-black.svg);
      }
    }
  }
}
