@import './variables.scss';
#tips {
    background-color: #313F2A;
    color: $creme;
    .flourish {
        opacity: 0.3;
        // background-image: url('../images/holiday/flourish.svg');
        &.bottom.left {
            left: 15px;
        }
        &.bottom.right {
            right: 15px;
        }
    }
    header {
        position: relative;
        .container {
            position: relative;
            h1,
            h2,
            .subhead {
                color: $white;
            }
        }
        .navigation {
            @include mobile {
                justify-content: center !important;
            }
            .share-wrap {
                @include mobile {
                    margin-right: 0 !important;
                }
            }
        }
    }
    main {
        padding-top: 59px;
        @include mobile {
            padding-top: 30px;
        }
        .filter {
            display: flex;
            justify-content: center;
            gap: 12px;
            flex-wrap: wrap;
            padding-bottom: 59px;
            position: relative;
            z-index: 4;
            .box {
                box-shadow: 6px 6px 6px 0px #0000003b;
                &.active:focus {
                    outline-color: $black;
                }
                &:not(.active):focus {
                    outline-color: $creme;
                }
            }
            @include mobile {
                padding-bottom: 40px;
            }
        }
        .tips-index {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            padding-bottom: 40px;
            max-width: 1300px;
            margin: 0 auto;
            @include mobile {
                grid-template-columns: 1fr;
            }
            &.hide {
                opacity: 0;
                transform: translateY(15px);
                transition: opacity ease 0.3s, transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.3s;
            }
            &.show {
                opacity: 1;
                transition: opacity ease 0.3s, transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.3s;
                transform: translateY(0px);
            }
        }
        .stars {
            &.five,
            &.six {
                @include mobile {
                    display: none;
                }
            }
        }
    }
    section.bottom {
        padding-bottom: 150px;
        position: relative;
        .fade {
            position: absolute;
            height: 430px;
            background: linear-gradient( 180deg, rgba(2, 0, 36, 0) 0%, rgba(23, 22, 21, 1) 77%);
            width: 100%;
            top: -430px;
            pointer-events: none;
        }
        .container {
            display: flex;
            justify-content: center;
            position: relative;
            padding-right: 15px;
            padding-left: 15px;
            button {
                max-width: 350px;
                font-weight: 500;
            }
        }
    }
    footer {
        background-color: $creme;
        color: $black;
    }
}