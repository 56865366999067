@import '../../variables.scss';
.FooterCallout{
  text-align: center;
  transition: all ease .5s .25s;
  .content{
    padding: 60px 0px;
    max-width: 1100px;
    width: 90%;
    margin: 130px auto;
    position: relative;
    background-color: $color-black;
    background-blend-mode: multiply;
    z-index: 1;
    &:after{
      content: '';
      width: 100.5%;
      height: 102%;
      position: absolute;
      display: block;
      background: $background-gradient;
      top: -1.25%;
      z-index: -1;
      left: -.25%;
    }
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      background: $color-black;
      top: 0%;
      z-index: 0;
      left: 0%;
      background-image: url(../../../public/img/background-pattern.png);
    }
  }
  h3{
    color: $color-white;
    @include font-book;
    z-index: 1;
    position: relative;
    margin-bottom: 10px;
  }
  &.hidden{
    opacity: 0;
    transform: translateY(20px);
  }
  &.fade-in-element{
    opacity: 1;
    transform: translateY(0px);
  }
}
